// https://github.com/ferdikoomen/openapi-typescript-codegen/blob/b2351c412bfbe3fb35d4c947f1d7bb29fbe37cc5/docs/custom-request-file.md

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ApiRequestOptions } from './ApiRequestOptions'
import { CancelablePromise } from './CancelablePromise'
import type { OpenAPIConfig } from './OpenAPI'

export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
): CancelablePromise<T> => {
  let controller: AbortController | null = null
  let signal: AbortSignal | null = null

  return new CancelablePromise((resolve, reject, onCancel) => {
    // @see ./src/templates/core/functions/getUrl.hbs
    // const url = `${config.BASE}${options.path}`

    const url = getUrl(config, options)
    const formData = getFormData(options)
    const body = getRequestBody(options)

    controller = new AbortController()
    signal = controller.signal

    onCancel(() => {
      if (controller) {
        controller.abort()
      }
    })

    fetchAuthorizedManager<T>(url, {
      body: formData || body,
      method: options.method,
      signal,
      // cancelToken: source.token,
    })
      .then((data) => {
        resolve(data as T)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const isDefined = <T>(
  value: T | null | undefined,
): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null
}

const isString = (value: any): value is string => {
  return typeof value === 'string'
}

// const isStringWithValue = (value: any): value is string => {
//   return isString(value) && value !== ''
// }

const isBlob = (value: any): value is Blob => {
  return (
    typeof value === 'object' &&
    typeof value.type === 'string' &&
    typeof value.stream === 'function' &&
    typeof value.arrayBuffer === 'function' &&
    typeof value.constructor === 'function' &&
    typeof value.constructor.name === 'string' &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  )
}

const isFormData = (value: any): value is FormData => {
  return value instanceof FormData
}

// const base64 = (str: string): string => {
//   try {
//     return btoa(str)
//   } catch (err) {
//     // @ts-ignore
//     return Buffer.from(str).toString('base64')
//   }
// }

const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = []

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
  }

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v)
        })
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v)
        })
      } else {
        append(key, value)
      }
    }
  }

  Object.entries(params).forEach(([key, value]) => {
    process(key, value)
  })

  if (qs.length > 0) {
    return `?${qs.join('&')}`
  }

  return ''
}

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]))
      }
      return substring
    })

  const url = `${config.BASE}${path}`
  if (options.query) {
    return `${url}${getQueryString(options.query)}`
  }
  return url
}

const getFormData = (options: ApiRequestOptions): FormData | undefined => {
  if (options.formData) {
    const formData = new FormData()

    const process = (key: string, value: any) => {
      if (isString(value) || isBlob(value)) {
        formData.append(key, value)
      } else {
        formData.append(key, JSON.stringify(value))
      }
    }

    Object.entries(options.formData)
      .filter(([_, value]) => isDefined(value))
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => process(key, v))
        } else {
          process(key, value)
        }
      })

    return formData
  }
  return undefined
}

// type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

// const resolve = async <T>(
//   options: ApiRequestOptions,
//   resolver?: T | Resolver<T>,
// ): Promise<T | undefined> => {
//   if (typeof resolver === 'function') {
//     return (resolver as Resolver<T>)(options)
//   }
//   return resolver
// }

// const getHeaders = async (
//   config: OpenAPIConfig,
//   options: ApiRequestOptions,
// ): Promise<Headers> => {
//   const token = await resolve(options, config.TOKEN)
//   const username = await resolve(options, config.USERNAME)
//   const password = await resolve(options, config.PASSWORD)
//   const additionalHeaders = await resolve(options, config.HEADERS)

//   const headers = Object.entries({
//     Accept: 'application/json',
//     ...additionalHeaders,
//     ...options.headers,
//   })
//     .filter(([_, value]) => isDefined(value))
//     .reduce(
//       (headers, [key, value]) => ({
//         ...headers,
//         [key]: String(value),
//       }),
//       {} as Record<string, string>,
//     )

//   if (isStringWithValue(token)) {
//     headers['Authorization'] = `Bearer ${token}`
//   }

//   if (isStringWithValue(username) && isStringWithValue(password)) {
//     const credentials = base64(`${username}:${password}`)
//     headers['Authorization'] = `Basic ${credentials}`
//   }

//   if (options.body) {
//     if (options.mediaType) {
//       headers['Content-Type'] = options.mediaType
//     } else if (isBlob(options.body)) {
//       headers['Content-Type'] = options.body.type || 'application/octet-stream'
//     } else if (isString(options.body)) {
//       headers['Content-Type'] = 'text/plain'
//     } else if (!isFormData(options.body)) {
//       headers['Content-Type'] = 'application/json'
//     }
//   }

//   return new Headers(headers)
// }

const getRequestBody = (options: ApiRequestOptions): any => {
  if (options.body) {
    if (options.mediaType?.includes('/json')) {
      return JSON.stringify(options.body)
    } else if (
      isString(options.body) ||
      isBlob(options.body) ||
      isFormData(options.body)
    ) {
      return options.body
    } else {
      return JSON.stringify(options.body)
    }
  }
  return undefined
}
