<script lang="ts" setup>
interface Props {
  type: 'full' | 'compact'
}

withDefaults(defineProps<Props>(), {
  type: 'full',
})

const config = useRuntimeConfig()
const isProd = config.public.ENV === 'production'
</script>

<template>
  <div class="flex h-full flex-col items-center">
    <div v-if="!isProd" class="flex justify-center">
      <span
        class="rounded-full bg-gray-700 px-3 text-center text-[8px] lg:-mt-1 lg:mb-1"
      >
        {{ config.public.ENV === 'development' ? 'dev' : config.public.ENV }}
      </span>
    </div>

    <img
      v-if="type === 'full'"
      src="/assets/img/Accurait.svg"
      width="168"
      height="36"
      alt="Accurait"
      class="self-center object-contain"
    />

    <img
      v-else
      src="/assets/accurait-logo-primary.svg"
      width="524"
      height="524"
      alt="Accurait"
      class="w-10 self-center object-contain"
    />
  </div>
</template>
