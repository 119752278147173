<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../../types'
import OptionExerciseDetailsTable from '@register/components/Review/Form/OptionExerciseDetails/Table.vue'
import { useFormModal } from './composables/useFormModal'
import Lock from '@ui/formkit/inputs/leaseDocumentInput/actions/lock/Lock.vue'

const props = defineProps<{ context: LeaseDocumentInputContext }>()
const openForm = useFormModal(toRef(props, 'context'))

const { dateFormat: divisionDateFormat } = useDivisions()
const { dateFormat: leaseDateFormat } = useCurrentLeaseDocument()
</script>

<template>
  <div v-auto-animate>
    <!-- Table -->
    <OptionExerciseDetailsTable
      :data="context.value.value"
      :label="context.label"
      :disabled="context.disabled"
      :is-required="context.field.isRequired"
      :is-approved="context.isApproved"
      :original-expiry-date="
        context.originalDates?.expiryDate ?? context.dates?.expiryDate
      "
      :current-expiry-date="context.dates?.expiryDate"
      :division-date-format="divisionDateFormat"
      :lease-date-format="leaseDateFormat"
      @edit="openForm"
    >
      <template #footer>
        <div v-if="context.canApprove" class="flex justify-end p-2">
          <Lock
            :context="context"
            :force-tooltip="
              context.isApproved
                ? `Edit ${context.field.name}`
                : `Approve ${context.field.name}`
            "
            title="Approve table"
          />
        </div>
      </template>
    </OptionExerciseDetailsTable>
  </div>
</template>
