import { LeaseStatus } from '@register'

const colorMap = {
  current:
    'bg-primary bg-opacity-20 text-primary border-primary hover:!bg-opacity-30 border border-primary',
  expired:
    'bg-red bg-opacity-20 text-red border-red hover:!bg-opacity-30 border border-red',
  holdover:
    'bg-warning bg-opacity-20 text-warning border-warning hover:!bg-opacity-50 border border-warning',
  others:
    'bg-gray-550 bg-opacity-20 text-white border-gray-550 hover:!bg-opacity-30 border border-gray-550',
}

export type LeaseStatusObject = { label: string; color: string }

export const leaseStatusObject: Record<LeaseStatus, LeaseStatusObject> = {
  [LeaseStatus.DRAFT]: {
    label: 'Draft',
    color: colorMap.others,
  },
  [LeaseStatus.LEASED]: {
    label: 'Leased',
    color: colorMap.current,
  },
  [LeaseStatus.EXPIRED]: {
    label: 'Expired',
    color: colorMap.expired,
  },
  [LeaseStatus.HOLDOVER]: {
    label: 'Holdover',
    color: colorMap.holdover,
  },
  [LeaseStatus.SURRENDERED]: {
    label: 'Surrendered',
    color: colorMap.expired,
  },
  [LeaseStatus.TRANSFERRED]: {
    label: 'Transferred',
    color: colorMap.expired,
  },
  [LeaseStatus.OPTION_EXERCISED]: {
    label: 'Option Exercised',
    color: colorMap.current,
  },
  [LeaseStatus.OPTION_EXPIRED]: {
    label: 'Option Expired',
    color: colorMap.expired,
  },
  [LeaseStatus.LEASE_EXTENDED]: {
    label: 'Lease Extended',
    color: colorMap.current,
  },
  [LeaseStatus.OPTION_EXERCISED_FUTURE_DATED]: {
    label: 'Option Exercised Future Dated',
    color: colorMap.current,
  },
  [LeaseStatus.NOT_SIGNED]: {
    label: 'Not Signed',
    color: colorMap.others,
  },
  [LeaseStatus.UNDER_NEGOTIATION]: {
    label: 'Under Negotiation',
    color: colorMap.others,
  },
  [LeaseStatus.OWNED]: {
    label: 'Owned',
    color: colorMap.others,
  },
  [LeaseStatus.CLOSED]: {
    label: 'Closed',
    color: colorMap.others,
  },
  [LeaseStatus.DELETED]: {
    label: 'Deleted',
    color: colorMap.others,
  },
}

export default Object.entries(leaseStatusObject).map(
  ([value, { label, color }]) => ({
    label,
    value,
    color,
  }),
)
const currents = [
  LeaseStatus.LEASED,
  LeaseStatus.OPTION_EXERCISED,
  LeaseStatus.LEASE_EXTENDED,
  LeaseStatus.OPTION_EXERCISED_FUTURE_DATED,
]
const expired = [
  LeaseStatus.EXPIRED,
  LeaseStatus.SURRENDERED,
  LeaseStatus.TRANSFERRED,
  LeaseStatus.OPTION_EXPIRED,
]
const others = [
  LeaseStatus.NOT_SIGNED,
  LeaseStatus.UNDER_NEGOTIATION,
  LeaseStatus.OWNED,
  LeaseStatus.CLOSED,
]
const holdOver = [LeaseStatus.HOLDOVER]

export const groups = {
  currents,
  expired,
  holdOver,
  others,
}
