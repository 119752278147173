import { AuthStatus, AuthRedirectCondition } from '~/composables/auth'

declare module '#app' {
  interface PageMeta {
    auth?: boolean | 'guest'
  }
}

export default defineNuxtRouteMiddleware((to) => {
  if (to.meta.auth === false) {
    return
  }

  const pageIsInGuestMode = to.meta.auth === 'guest'
  const { status, signIn } = useAuth()
  const { setRedirect } = useAuthRedirect()

  if (status.value === AuthStatus.Unauthenticated && !pageIsInGuestMode) {
    // remember destination and redirect to login
    setRedirect({
      url: to.fullPath,
      condition: AuthRedirectCondition.Authenticated,
    })
    return nextTick().then(() => signIn())
  }

  if (status.value === AuthStatus.Authenticated && pageIsInGuestMode) {
    // Redirect to home
    return navigateTo('/')
  }
})
