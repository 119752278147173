<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

const { data: user, role } = useAuth()
const { signOut } = useAuth()

function onClickMenu() {
  useAnalytics({
    name: 'Navigation: Open Profile Dropdown Menu',
    showDivision: true,
  })
}
</script>

<template>
  <Menu ref="popover" v-slot="{ close }" as="div" class="relative">
    <MenuButton
      class="group inline-flex rounded-md focus:outline-none"
      @click="onClickMenu"
    >
      <span
        class="flex h-10 w-10 justify-center rounded-lg text-center group-hover:bg-primary group-hover:text-black"
      >
        <span
          class="flex h-6 w-6 justify-center self-center rounded-lg text-center text-xs leading-4 text-white group-hover:text-black"
        >
          <NuxtIcon
            name="user-profile"
            filled
            class="text-2xl text-white group-hover:text-black"
          />
        </span>
      </span>

      <NuxtIcon
        name="chevron-down"
        filled
        class="h-3 w-3 self-center text-sm text-white transition duration-150 ease-in-out group-hover:text-primary"
        aria-hidden="true"
      />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 mt-2 flex w-72 max-w-[calc(100vw-50px)] origin-top-right flex-col gap-1 rounded-md bg-gray-900 p-2 shadow-lg"
      >
        <div
          class="flex w-full flex-col rounded-lg !bg-transparent px-4 py-2 text-sm !text-white"
          to="/settings/profile"
        >
          <span class="-ml-1 flex gap-2">
            <UserPicture :name="user?.name" :email="user?.email ?? 'XX'" />
            <span class="grid grid-cols-1">
              <span v-if="user?.name" class="truncate text-lg font-bold">
                {{ user?.name }}
              </span>
              <span class="truncate text-xs text-gray-300">
                {{ user?.email }}
              </span>
            </span>
          </span>
          <span
            class="mt-2 flex w-full divide-x divide-gray-700 text-xs text-gray-500 *:px-3 first:*:pl-0 last:*:pr-0"
          >
            <span>
              {{ title(user?.organization?.type) }}
            </span>

            <span>
              {{ title(role.displayName) }}
            </span>
          </span>

          <span class="mt-2 text-xs"> </span>
        </div>

        <hr class="my-2 h-px border-t-0 bg-gray-850" />

        <MenuItem v-slot="{ active }">
          <Button
            color="transparentSecondary"
            :class="[active ? 'bg-gray-800 text-primary' : 'text-white']"
            class="w-full justify-start hover:text-primary"
            to="/settings/profile"
            icon="user-profile"
            @click.capture="close()"
          >
            Your profile
          </Button>
        </MenuItem>

        <hr class="my-2 h-px border-t-0 bg-gray-850" />

        <MenuItem v-slot="{ active }">
          <Button
            color="transparentSecondary"
            :class="[active ? 'bg-gray-800 text-primary' : 'text-white']"
            class="w-full justify-start hover:text-primary"
            to="/settings/divisions"
            icon="folder-group-copy"
            @click.capture="close()"
          >
            Divisions
          </Button>
        </MenuItem>

        <MenuItem v-slot="{ active }">
          <Button
            color="transparentSecondary"
            :class="[active ? 'bg-gray-800 text-primary' : 'text-white']"
            class="w-full justify-start hover:text-primary"
            to="/settings/users"
            icon="users"
            @click.capture="close()"
          >
            Users
          </Button>
        </MenuItem>

        <hr class="my-2 h-px border-t-0 bg-gray-850" />

        <MenuItem v-slot="{ active }">
          <Button
            color="transparentDanger"
            :class="[active ? 'bg-red text-white' : 'text-danger']"
            class="flex w-full cursor-pointer justify-start rounded-lg px-4 py-2 text-sm"
            icon="logout"
            @click="signOut"
          >
            Sign out
          </Button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<style lang="postcss" scoped>
:deep(.router-link-active) {
  @apply bg-gray-750 text-primary;
}
</style>
