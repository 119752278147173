<script setup lang="ts">
import { FormKit } from '@formkit/vue'

import type { DocumentForModal } from '@workflow'

interface Props {
  title: string
  documents?: DocumentForModal[]
  body?: string
  submitButton?: string
  cancelButton?: string
  confirmInputText?: string
  loading?: boolean
  confirmClass?: string
  cancelClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  documents: undefined,
  body: undefined,
  submitButton: 'Submit',
  cancelButton: 'Cancel',
  confirmInputText: undefined,
  loading: false,
  confirmClass:
    'bg-danger hover:bg-danger/90 text-white focus:ring-danger/20 focus:border-danger focus:bg-danger/90',
  cancelClass: 'hover:bg-gray-700 text-white',
})

const emit = defineEmits(['close', 'confirm'])
const form = ref<typeof FormKit & { node: any }>(null!)
const elInput = ref()
const title = unref(props.title)
const documents = unref(props.documents)
const body = unref(props.body)

//on submit, check if confirmInputText matches
function onSubmit(data: { confirmText?: string }): void {
  const confirmation = props.confirmInputText?.toLocaleLowerCase()
  if (confirmation && data.confirmText?.toLowerCase() !== confirmation) {
    if (!form.value) return
    form.value.node.setErrors({
      confirmText: `Type ${props.confirmInputText} to confirm`,
    })
    return
  }
  emit('confirm')
}

//focus input on open
onMounted(() => {
  elInput.value = document.querySelector('.type-confirm > div > input')
  if (!elInput.value) return
  nextTick(() => {
    elInput.value.focus()
  })
})
</script>

<template>
  <ModalBase :title="title" @close="$emit('close')">
    <div class="mx-auto">
      <p v-if="body" class="whitespace-break-spaces">
        {{ body }}
      </p>

      <div v-if="documents">
        <p class="font-bold">
          {{ documents.length }}
          {{ documents.length > 1 ? 'documents' : 'document' }}
        </p>
        <ul class="list-decimal pl-4 text-xs">
          <li
            v-for="document in documents"
            :key="document.id"
            class="font-neutral-500 mt-2 dark:text-neutral-400"
          >
            {{ document.name ?? document.filename }}
          </li>
        </ul>
      </div>
    </div>
    <template #footer>
      <FormKit ref="form" type="form" :actions="false" @submit="onSubmit">
        <div v-if="confirmInputText" class="my-4">
          Please type
          <span class="text-lg font-bold">{{ confirmInputText }}</span> to
          confirm.

          <FormKit
            name="confirmText"
            type="text"
            wrapper-class="type-confirm mt-2"
          />
        </div>
        <div class="mt-6 flex justify-end gap-2">
          <Button
            :disabled="loading"
            color="transparentSecondary"
            :class="cancelClass"
            @click.prevent="emit('close')"
          >
            {{ cancelButton }}
          </Button>

          <Button
            type="submit"
            :disabled="loading"
            :loading="loading"
            class="mr-2"
            :class="confirmClass"
            @click.prevent="form?.node.submit()"
          >
            {{ submitButton }}
          </Button>
        </div>
      </FormKit>
    </template>
  </ModalBase>
</template>
