<script lang="ts" setup>
import { AuthStatus } from '~/composables/auth'
import { useWindowSize } from '@vueuse/core'
import { Product } from '~/composables/useAPI/types'

const { status } = useAuth()
const { width: widthWindow } = useWindowSize()

const isAuthenticated = computed(
  () => status.value === AuthStatus.Authenticated,
)
const { currentDivision, createDivisionURL, companies } = useDivisions()

const hasMultipleDivisions = computed(() => {
  return companies.value.length > 1
})

const firstDivision = computed(() => {
  return companies.value[0]
})

const reminderCountAPI = useApiRemindersCount()
const reminderCount = computed(() => reminderCountAPI.data.value?.count ?? 0)
</script>

<template>
  <div class="flex h-full w-full justify-end gap-x-1">
    <div class="flex grow items-center justify-start lg:gap-x-1">
      <NuxtLink
        to="/"
        class="flex max-w-[100px] focus:appearance-none focus:outline-none focus:outline focus:outline-offset-4 lg:min-w-[100px]"
      >
        <AccuraitLogo
          class="h-8 self-center"
          :type="widthWindow > 980 ? 'full' : 'compact'"
        />
      </NuxtLink>

      <SelectCompanyDropdown v-if="hasMultipleDivisions" class="flex" />

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="-translate-y-4 opacity-0"
        enter-to-class="translate-y-0 opacity-100 "
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="-translate-y-1 opacity-0 "
      >
        <!-- On single division, always show the links -->
        <div
          v-if="(!hasMultipleDivisions && firstDivision?.id) || currentDivision"
          class="ml-2 hidden w-full grow flex-row lg:flex"
        >
          <NavigationButtonIcon :to="createDivisionURL()" title="Dashboard" />

          <NavigationButtonIcon
            :to="createDivisionURL('workflow')"
            title="Upload & Review"
          />

          <NavigationButtonIcon
            :to="createDivisionURL('portfolio')"
            title="Portfolio"
          />

          <NavigationButtonIcon
            v-if="$can('product', Product.CRITICAL_DATES)"
            :to="createDivisionURL('critical-dates')"
            title="Reminders"
            :alert="reminderCount > 0"
            :tooltip="
              reminderCount ? `${reminderCount} reminders for today` : undefined
            "
          />

          <NavigationButtonIcon
            :to="createDivisionURL('reports')"
            title="Reports"
          />
        </div>
      </transition>
    </div>

    <div class="flex shrink items-center justify-end gap-x-1">
      <!-- Search -->
      <!-- <NavigationSearch v-if="isAuthenticated" /> -->

      <!-- Upload button -->
      <NavigationUploadingButton v-if="isAuthenticated" />

      <!-- Notification button -->
      <NavigationNotificationButton class="hidden md:block" />

      <!-- Profile button -->
      <NavigationProfileButton />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.nav-icon {
  @apply hover:text-black;
}
.nav-link {
  @apply relative pb-4;
  &:before {
    @apply hover:bg-primary absolute -bottom-0.5 left-0 right-0 h-1 content-[''];
  }
}
.router-link-active {
  @apply text-primary;
  &:before {
    @apply bg-primary;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease;
  max-height: 400px;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
