import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { QueryFunction } from '@tanstack/query-core'
import type {
  IGetCurrentFieldValueResponse,
  IPortfolioLeaseFieldValue,
} from '@register'

const keyLeaseCurrentValue = (
  companyId: string,
  leaseId: string,
  fieldId: string,
) => [
  'companies',
  companyId,
  'leases',
  leaseId,
  'fields',
  fieldId,
  'currentValue',
]

const fetchLeaseCurrentValue =
  (
    companyId: string,
    leaseId: string,
    fieldId: string,
  ): QueryFunction<IGetCurrentFieldValueResponse, string[]> =>
  ({ signal }) =>
    fetchAuthorized(
      `/companies/${companyId}/leaseRegister/leases/${leaseId}/fields/${fieldId}/currentValue`,
      { signal },
    )

const fetchLeaseCurrentValues =
  (
    companyId: string,
    leaseId: string,
    fieldIds: string[],
  ): QueryFunction<IPortfolioLeaseFieldValue, string[]> =>
  ({ signal }) =>
    fetchAuthorized(
      `/companies/${companyId}/portfolio/leases/${leaseId}/fieldValues`,
      {
        signal,
        params: {
          fieldIds,
        },
      },
    )

export const useApiLeaseCurrentValue = (
  companyId: string,
  leaseId: string,
  fieldId: string,
) => {
  return useQuery({
    queryKey: keyLeaseCurrentValue(companyId, leaseId, fieldId),
    queryFn: fetchLeaseCurrentValue(companyId, leaseId, fieldId),
  })
}

export const useApiLeaseCurrentValues = (
  companyId: string,
  leaseId: string,
  fieldIds: string[],
) => {
  return useQuery({
    queryKey: keyLeaseCurrentValue(companyId, leaseId, fieldIds.join(',')),
    queryFn: fetchLeaseCurrentValues(companyId, leaseId, fieldIds),
  })
}

export const useApiPrefetchLeaseCurrentValue = async (
  companyId: string,
  leaseId: string,
  fieldId: string,
) => {
  const queryClient = useQueryClient()

  await queryClient.prefetchQuery({
    queryKey: keyLeaseCurrentValue(companyId, leaseId, fieldId),
    queryFn: fetchLeaseCurrentValue(companyId, leaseId, fieldId),
  })
}
