<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'

import type { LeaseDocumentInputContext } from '../types'

interface Props {
  context: LeaseDocumentInputContext
  modelValue?: any
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
})

const emit = defineEmits(['update:modelValue'])
const innerValue = useVModel(props, 'modelValue', emit)
</script>

<template>
  <Listbox v-model="innerValue" as="div">
    <ListboxButton
      v-if="!context.isApproved"
      class="absolute right-0 top-1/2 flex h-6 w-8 -translate-y-1/2 justify-center self-start rounded-lg py-2.5 focus:outline-none focus-visible:border focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-[#545A3B]"
    >
      <NuxtIcon name="chevron-down" filled class="self-center" />
    </ListboxButton>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <ListboxOptions
        class="absolute left-0 top-full z-50 mt-2 max-h-60 w-full overflow-auto scroll-smooth rounded-lg border border-gray-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800"
      >
        <ListboxOption
          v-for="(option, idx) in context.fieldConfig.options"
          :key="idx"
          :value="option.value"
          class="cursor-pointer whitespace-nowrap p-3 text-sm text-gray-200 transition duration-100 ui-selected:text-white ui-active:text-white ui-selected:dark:bg-gray-750 ui-active:dark:bg-gray-700"
        >
          {{ option.label }}
        </ListboxOption>
      </ListboxOptions>
    </Transition>
  </Listbox>
</template>
