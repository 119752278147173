<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../../types'
import { computed } from 'vue'
import { useInputValue } from '../composables/useInputValue'

const googleApiKey = useRuntimeConfig().public.googleApiKey
const props = defineProps<{ context: LeaseDocumentInputContext }>()
const addressNode = computed(() => props.context.fieldAddressNode)

const { approvedValue, inputValue } = useInputValue(toRef(props, 'context'))

const latLng = computed(() => {
  const lat = addressNode.value?.context._value.value?.latitude
  const lng = addressNode.value?.context._value.value?.longitude
  if (!lat || !lng) {
    return { lat: -25, lng: 134 }
  }

  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }
})

const activeMarkers = computed(() => {
  return [
    {
      position: { lat: latLng.value.lat, lng: latLng.value.lng },
    },
  ]
})

const mapRef = ref<HTMLElement>()
const { map } = useGoogleMaps(mapRef, {
  zoom: 14,
  maxZoom: 16,
  center: {
    lat: latLng.value.lat,
    lng: latLng.value.lng,
  },
  mapId: '4162bfc8ea6f3177',
})

useGoogleMapsMarkers(map, activeMarkers, {
  markerClusterer: true,
  infoWindow: () => {
    const imgSrc = `background-image: url(https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${latLng.value.lat},${latLng.value.lng}&key=${googleApiKey}); background-size: cover`

    const content = /*html*/ `
      <div>
        <div class="flex flex-row items-stretch">
          <div
            class="h-full min-h-[100px] w-[130px] min-w-[130px] max-w-[130px]  rounded-md"
            style="${imgSrc}">
          </div>
          <div class="flex flex-auto flex-col gap-1 pl-2 text-black">
            <p>
              <b>${approvedValue.value}</b>
            </p>
          </div>
        </div>
      </div>
      `

    return {
      content,
    }
  },
})
</script>

<template>
  <div class="mb-4 mr-10">
    <h3 class="mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ context.field.name }}
    </h3>
    <div
      v-if="addressNode?.context._value.value"
      ref="mapRef"
      class="h-[300px] w-full rounded-lg"
    />
    <div
      v-else
      class="flex h-[300px] w-full items-center justify-center rounded-lg bg-gray-750"
    >
      Add an address to see it here
    </div>
  </div>
</template>
