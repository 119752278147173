import type { ColumnDef, ColumnDefResolved } from '@tanstack/vue-table'
import { resolveColumnId } from '@ui/components/TanstackTable/utils'
import { createColumnHeader } from '@ui/components/TanstackTable/columns/utils'

export const createDynamicColumn = (
  column: ColumnDef<any, any>,
  slots: ReturnType<typeof useSlots>,
): ColumnDef<any, any> => {
  return {
    ...column,
    id: resolveColumnId(column as ColumnDefResolved<any, any>)!,
    header: (props) => createColumnHeader(column, slots, props),
    cell: (props) => {
      const item = props.row.original
      const value =
        typeof column.cell === 'function'
          ? column.cell(props)
          : column.cell ?? props.getValue()
      const slot = slots[`item-${props.column.id}`] ?? slots.item

      if (slot) {
        const slotProps = column.meta?.slotProps ?? {}
        return slot({
          ...slotProps,
          id: props.column.id,
          value,
          item,
          index: props.row.index,
        })
      }

      return value
    },
  }
}
