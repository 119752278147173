export default defineNuxtPlugin(async (nuxtApp) => {
  const { data } = useAuthState()
  const { fetchUser } = useAuth()
  // const headers = useRequestHeaders(['cookie'])

  // Only fetch session if it was not yet initialized server-side
  if (typeof data.value !== 'undefined') {
    return
  }

  const route = useRoute()
  if (route.path !== '/callback') {
    await fetchUser()
  }
})
