import { useModal } from 'vue-final-modal'
import type { MaybeRef } from '~/types/helpers'
import type { DocumentForModal } from '@workflow'
import ModalWorkflowConfirmation from '~/components/Modal/ModalWorkflowConfirmation.vue'

export interface UseConfirmOptions {
  title: MaybeRef<string>
  body?: MaybeRef<string>
  documents?: MaybeRef<DocumentForModal[]>
  loading?: boolean
  submitButton?: MaybeRef<string>
  cancelButton?: MaybeRef<string>
  confirmInputText?: MaybeRef<string | undefined>
  confirmClass?: string
  cancelClass?: string
  confirm?: () => Promise<void> | void
  close?: () => Promise<void> | void
  bypass?: () => boolean
  onClosed?: () => Promise<void> | void
  showCloseButton?: boolean
}

export function useConfirm() {
  let innerClose: () => Promise<string> | void = () => {}

  const require = (options: UseConfirmOptions) => {
    const {
      title = 'Confirmation',
      body = 'Are you sure you want to do this?',
      documents,
      loading,
      submitButton = 'Confirm',
      cancelButton = 'Cancel',
      confirmInputText,
      confirmClass,
      cancelClass,
      confirm = () => {},
      close = () => {},
      bypass = () => false,
      onClosed = () => {},
      showCloseButton = true,
    } = options

    // Close previous modal
    innerClose()

    // Bypass the confirmation
    if (bypass()) {
      confirm()
      return
    }

    // Open new modal
    const { close: _close } = useModal<
      InstanceType<typeof ModalWorkflowConfirmation>['$props']
    >({
      defaultModelValue: true,
      component: ModalWorkflowConfirmation,
      attrs: {
        title: toValue(title),
        body: toValue(body),
        documents,
        loading,
        submitButton: toValue(submitButton),
        cancelButton: toValue(cancelButton),
        confirmInputText: toValue(confirmInputText),
        confirmClass,
        cancelClass,
        onConfirm: async () => {
          await confirm()
          _close()
        },
        onClose: async () => {
          await close()
          _close()
        },
        onClosed: () => onClosed(),
        showCloseButton,
      },
    })

    // Assign close function to `close` variable
    innerClose = _close
  }

  return {
    require,
    close: innerClose,
  }
}
