import Uppy from '@uppy/core'
import XHR from '@uppy/xhr-upload'
import {
  FileFullObject,
  IFileUpload,
  IFileUploadObject,
  IFilesData,
  IFilesResult,
  IQueueType,
} from './upload.types'

import { toast } from 'vue3-toastify'

export const FILE_SIZE_UPLOAD_LIMIT: number = 60 * 1024 * 1024

type UploadReturn = {
  files: ComputedRef<IFilesResult>
  uploader: (companyId: string) => Uppy
  appendCompany: (companyId: string) => void
  removeCompany: (companyId: string) => void
  removeFile: (companyId: string, fileId: string) => void
  retryAll: () => void
  retryFile: (companyId: string, fileId: string) => void
}

export const useUploadStore = defineStore('upload', (): UploadReturn => {
  const { companies, getDivisionById } = useDivisions()

  const _files = reactive<IFileUpload>({})

  const files = computed<IFilesResult>(() => {
    const mapAndFilter = (status: IQueueType) =>
      _companiesUpload
        .map((companyUpload) => ({
          files: companyUpload[status],
          company: companyUpload.company,
        }))
        .filter((item) => item.files.length > 0)
    const _companiesUpload = Object.values(_files)
    const _finishedFiles: IFilesData[] = mapAndFilter(IQueueType.finished)
    const _errorFiles: IFilesData[] = mapAndFilter(IQueueType.error)

    // prevent to show files that are already finished or with error
    const upFiles: IFilesData[] = mapAndFilter(IQueueType.uploading)
      .map<IFilesData>((item) => ({
        ...item,
        files: item.files.filter(
          (file) =>
            !_finishedFiles.some((finishedItem) =>
              finishedItem.files.some((f) => f.file.id === file.file.id),
            ) &&
            !_errorFiles.some((errorItem) =>
              errorItem.files.some((e) => e.file.id === file.file.id),
            ),
        ),
      }))
      .filter((item) => item.files.length > 0)

    return {
      [IQueueType.finished]: _finishedFiles,
      [IQueueType.error]: _errorFiles,
      [IQueueType.uploading]: upFiles,
    }
  })

  // Get uppy instance for the company provided
  function uploader(companyId: string): Uppy {
    if (_files[companyId]?.uppy) {
      const uppy = _files[companyId]?.uppy

      return uppy
    }
    throw new Error('Company not found')
  }

  // append new companies if user add a new one
  watch(
    companies,
    (companies) => companies.forEach((company) => appendCompany(company.id)),
    { immediate: true },
  )

  // When the user add a company, we also need to add here
  function appendCompany(companyId: string) {
    _files[companyId] = uploaderFactory(companyId)
  }

  // When the user delete a company, we also need to delete here
  function removeCompany(companyId: string) {
    const uppy = _files[companyId].uppy
    uppy.cancelAll()
    uppy.close()
    uppy.logout()
    delete _files[companyId]
  }

  function removeFile(companyId: string, fileId: string) {
    _files[companyId].uppy.removeFile(fileId)
  }

  function retryFile(companyId: string, fileId: string) {
    const errIndex = _getFileIndex(companyId, fileId, IQueueType.error)
    if (errIndex !== -1) {
      _files[companyId].error.splice(errIndex!, 1)
    }
    _files[companyId].uppy.retryUpload(fileId)
  }

  function retryAll() {
    for (const [_, uploader] of Object.entries(_files)) {
      uploader.uppy.retryAll()
      uploader.error = []
    }
  }

  return {
    files,
    uploader,
    appendCompany,
    removeCompany,
    removeFile,
    retryAll,
    retryFile,
  }

  function _getFileIndex(
    companyId: string,
    id: string,
    type: IQueueType,
  ): number | undefined {
    const queue = _files[companyId][type]
    return queue.findIndex((file) => file.file.id === id)
  }

  function uploaderFactory(companyId: string): FileFullObject {
    const apiURL = useRuntimeConfig().public.apiURL
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: FILE_SIZE_UPLOAD_LIMIT,
        maxNumberOfFiles: 2000,
        minNumberOfFiles: 1,
        allowedFileTypes: ['application/pdf'],
      },
    })
      .use(XHR, {
        endpoint: `${apiURL}/companies/${companyId}/imports`,
        bundle: false,
        fieldName: 'files',
        withCredentials: true,
      })
      .on('upload-progress', (file, progress) => {
        if (!file) return

        const index = _getFileIndex(companyId, file.id, IQueueType.uploading)
        if (index !== -1) {
          _files[companyId].uploading[index!].progress = Math.round(
            (progress.bytesUploaded / progress.bytesTotal) * 100,
          )
        }
      })
      .on('file-added', (file) => {
        useAnalytics({
          name: 'Upload: File added',
          showDivision: true,
          data: {
            source: file.source,
            name: file.name,
            size: file.size,
            type: file.type,
          },
        })

        _files[companyId].uploading.push({ file, progress: 0 })
      })
      .on('upload-success', (file, response) => {
        _files[companyId].finished.push({
          file: file!,
          progress: 100,
          response,
        })
        const mixpanel = useMixpanel()
        mixpanel.people?.increment('Total Files Uploaded')
        useAnalytics({
          name: 'Upload: Completed',
          showDivision: true,
          data: {
            source: file?.source,
            name: file?.name,
            size: file?.size,
            type: file?.type,
          },
        })
      })
      .on('error', (error) => {
        console.error(error.stack, { error })
      })
      .on('upload-error', (file, error, response) => {
        if (!file) return
        const obj: IFileUploadObject = {
          file,
          error,
          response,
          progress: file.progress?.percentage ?? 0,
        }
        _files[companyId].error.push(obj)

        let message = `There was an error to upload ${file.name}`
        if (error.message) {
          message = `<strong class="font-semibold">${file!.name}</strong> \n${
            error.message
          }`
        }
        toast.error(message)
        useAnalytics({
          name: 'Upload: Error',
          showDivision: true,
          data: {
            source: file.source,
            name: file.name,
            size: file.size,
            type: file.type,
            message: error.message,
          },
        })
      })
      .on('upload-retry', (fileID) => {
        useAnalytics({
          name: 'Upload: Retry',
          showDivision: true,
          data: { fileID: fileID },
        })
      })
      .on('restriction-failed', (file, error) => {
        if (!file) return

        const oldFileWithError = uppy
          .getFiles()
          .filter((f) => file.name === f.name && f.error)

        const isCurrentDuplicated = error.message.includes(
          'Cannot add the duplicate file',
        )

        if (oldFileWithError.length > 0 && isCurrentDuplicated) {
          uppy.removeFile(oldFileWithError[0].id)
          setTimeout(() => {
            uppy.addFile(file)
          }, 200)
        } else {
          toast.error(
            `<strong class="font-semibold">${file!.name}</strong> \n${
              error.message || error.name
            }`,
          )
          useAnalytics({
            name: 'Upload: Restriction Failed',
            showDivision: true,
            data: {
              source: file?.source,
              name: file?.name,
              size: file?.size,
              type: file?.type,
              message: error.message || error.name,
            },
          })
        }
      })
      .on('file-removed', (file, reason) => {
        const upIndex = _getFileIndex(companyId, file.id, IQueueType.uploading)
        if (upIndex !== -1) {
          _files[companyId].uploading.splice(upIndex!, 1)
        }
        const errIndex = _getFileIndex(companyId, file.id, IQueueType.error)
        if (errIndex !== -1) {
          _files[companyId].error.splice(errIndex!, 1)
        }
        const fIndex = _getFileIndex(companyId, file.id, IQueueType.finished)
        if (fIndex !== -1) _files[companyId].finished.splice(fIndex!, 1)
      })

    return {
      uppy,
      uploading: [],
      finished: [],
      error: [],
      company: getDivisionById(companyId)!,
    }
  }
})
