<script setup lang="ts">
import { ref, watchEffect } from '#imports'
import type { IconName } from '#build/types/icons'

type ValidIconNames<T> = T extends infer U ? U extends string ? (string extends U ? never : U) : never : never;

type Props = {
  name: IconName
  filled?: boolean
  fallback?: ValidIconNames<IconName> | undefined
}

const props = withDefaults(defineProps<Props>(), {
  filled: true,
})

const icon = ref<string | Record<string, any>>('')
let hasStroke = false
const hasError = ref<boolean>(false)

async function getIcon() {
  try {
    const iconsImport = import.meta.glob(
      ['/assets/icons/**/**.svg', '@ui/assets/icons/**/**.svg'],
      {
        query: '?raw',
        import: 'default',
        eager: false,
      },
    ) as Record<string, () => Promise<string>>


      function getIconURL(icon: string) {
        return Object.keys(iconsImport).find((path) => {
        const pathSegments = path.split('/')
        if (icon.includes('/')) {

          return path.includes(icon + '.svg')
        } else {
          return pathSegments[pathSegments.length - 1] === icon + '.svg'
        }
      })!
      }

    let iconKey = getIconURL(props.name)

    if (!iconKey && props.fallback) {
      console.log({ iconKey, ballback: props.fallback })
      iconKey = getIconURL(props.fallback)
      console.log(iconKey)
    }

    const rawIcon = await iconsImport[iconKey]()

    if (rawIcon.includes('stroke')) {
      hasStroke = true
    }
    icon.value = rawIcon
    hasError.value = false
  } catch (error) {
    hasError.value = true
    console.warn(
      `[nuxt-icons] Icon '${props.name}' doesn't exist in '/assets/icons'`,
    )
  }
}

await getIcon()

watchEffect(getIcon)
</script>

<template>
  <span
    v-if="!hasError"
    class="nuxt-icon"
    :class="{
      'nuxt-icon--fill': !filled,
      'nuxt-icon--stroke': hasStroke && !filled,
    }"
    v-html="icon"
  />
</template>

<style>
.nuxt-icon svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
  fill: currentColor !important;
}

.nuxt-icon.nuxt-icon--stroke,
.nuxt-icon.nuxt-icon--stroke * {
  stroke: currentColor !important;
}
</style>
