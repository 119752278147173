import { localizeFromEnum } from '~/utils/localizeFromEnum'
import { LeaseVariationType, DocumentType, localizeVariationType } from '@register'
export const leaseVariationTypeOptions = localizeFromEnum(
  LeaseVariationType,
  localizeVariationType,
)

export function getDocumentTypeFromVariationType(type?: DocumentType) {
  switch (type) {
    case DocumentType.SURRENDER:
      return LeaseVariationType.SURRENDER
    case DocumentType.TRANSFER:
      return LeaseVariationType.TRANSFER
    case DocumentType.VARIATION:
      return LeaseVariationType.VARIATION
    case DocumentType.OPTION_EXERCISE:
      return LeaseVariationType.OPTION_EXERCISE
    case DocumentType.EXTENSION:
      return LeaseVariationType.EXTENSION
    default:
      return undefined
  }
}
