import { useStorage } from '@vueuse/core'
import type { AuthRedirect } from './types'
import { AuthRedirectCondition, AuthStatus } from './constants'
import type { NavigateToOptions } from '#app/composables/router'

export const useAuthRedirect = () => {
  const { status } = useAuthState()
  const redirect = useStorage<AuthRedirect | null>(
    'auth:redirect',
    null,
    undefined,
    {
      serializer: {
        read: (v: any) => (v ? JSON.parse(v) : null),
        write: (v: any) => JSON.stringify(v),
      },
    },
  )

  const isValidRedirect = (): boolean => {
    if (!redirect.value?.url) {
      return false
    }

    if (redirect.value.condition === AuthRedirectCondition.Authenticated) {
      return status.value === AuthStatus.Authenticated
    }

    if (redirect.value.condition === AuthRedirectCondition.Unauthenticated) {
      return status.value === AuthStatus.Unauthenticated
    }

    return false
  }

  const setRedirect = (to: AuthRedirect) => {
    redirect.value = to
  }

  const clearRedirect = () => {
    redirect.value = null
  }

  const redirectTo = (to?: string, options?: NavigateToOptions) => {
    // Temp vars
    const url = redirect.value?.url
    const isValid = isValidRedirect()

    // Clear before redirect
    clearRedirect()

    // Not valid
    if (!isValid && !to) return
    // Not valid, but with fallback
    if (!isValid && to) return navigateTo(to, options)
    // Valid
    return navigateTo(url!, options)
  }

  return {
    redirect,
    setRedirect,
    isValidRedirect,
    clearRedirect,
    redirectTo,
  }
}
