<script setup lang="ts">
import type { ICompanies } from '~/composables/useAPI/types'
import type { IconName } from '#build/types/icons'
import Listbox from '@ui/components/Form/Listbox'

const companiesDropdownEl = ref<InstanceType<typeof Listbox> | null>(null)

const { currentDivision, companies, getDivisionById, createDivisionURL } =
  useDivisions()

function focus(): any {
  // @ts-expect-error
  companiesDropdownEl.value?.activate()
}

const { isMacOS, isDesktop } = useDevice()

function handleKeyDown(event: any) {
  if (isMacOS && event.metaKey && event.shiftKey && event.code === 'KeyC') {
    event.preventDefault()
    focus()
  } else if (
    isDesktop &&
    event.ctrlKey &&
    event.shiftKey &&
    event.code === 'KeyC'
  ) {
    event.preventDefault()
    focus()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown, false)
})

onUnmounted(() => {
  window.addEventListener('keydown', handleKeyDown, false)
})

function customLabel(company: ICompanies) {
  return `${company.name} - ${company.countryId}`
}

const router = useRouter()
function onChange(company: ICompanies) {
  router.push(createDivisionURL(undefined, company?.id))
}
</script>

<template>
  <div class="flex h-[60px] md:max-w-[170px]">
    <Listbox
      ref="companiesDropdownEl"
      class="relative mt-0.5 w-full max-w-full"
      :options="companies"
      track-by="id"
      label-by="name"
      close-on-select
      :searchable="true"
      placeholder="Select a division"
      :custom-label="customLabel"
      :model-value="currentDivision"
      @select="onChange"
    >
      <template #selection="{ value }">
        <h2 v-if="value.name" class="flex h-full flex-col">
          <span class="text-xs text-gray-500">division</span>
          <span
            class="flex-no-wrap sm:text-md -mt-1 flex text-sm font-bold text-white"
          >
            <span class="truncate">
              {{ value.name || 'Select a division' }}
            </span>
          </span>
        </h2>
        <h2 v-else class="mt-1 font-bold">Loading...</h2>
      </template>

      <!--  -->
      <template #listitem="{ option }">
        <div
          class="flex items-center text-base"
          :class="{
            'pl-8': !!option.parentId,
          }"
        >
          <span
            class="mask mask-squircle flex h-[20px] w-[20px] overflow-hidden rounded-md"
          >
            <NuxtIcon
              :name="`flags-1x1/${option.countryId?.toLowerCase()}` as IconName"
              class="text-xl"
              filled
            />
          </span>
          <span class="option__desc ml-4">
            {{ option.name }}
            <span v-if="companies && !!option.parentId" class="text-xs">
              (child of
              {{ getDivisionById(option.parentId)?.name }})
            </span>
          </span>
        </div>
      </template>
    </Listbox>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.multiselect__tags) {
  @apply cursor-pointer bg-transparent;
}

:deep(.multiselect__content) {
  @apply max-w-full;
}
:deep(.multiselect__content-wrapper) {
  @apply ring-primary md:w-[550px];
}
:deep(.multiselect__select) {
  @apply mt-2;
}

:deep(.multiselect__input) {
  @apply mt-2;
}

:deep(.multiselect__tags) {
  @apply min-w-[180px]	border-primary ring-primary;
}
:deep(:not(.multiselect-group) .multiselect__option--highlight) {
  &::after {
    @apply hidden;
  }
}
.multiselect-group .multiselect__option--highlight {
  @apply bg-primary-500;

  &::after {
    @apply h-full bg-primary-500;
  }
}

:deep(.multiselect__option--selected) {
  @apply bg-primary;
}

:deep(.multiselect__option--highlight) {
  @apply bg-primary;

  &::after {
    @apply bg-primary;
  }
}

:deep(.multiselect__placeholder) {
  @apply mt-2 font-bold;
}

:deep(.option__desc) {
  @apply max-w-full truncate;
}
</style>
