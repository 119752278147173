import type { ToRefs } from 'vue'
import type { FormKitNode } from '@formkit/core'
import type { IRentIncreaseTableForm } from '../types'
import { RENT_INCREASE_TABLE_METHODS } from '../constants'
import { IncreaseTableMethod } from '@register'

export const useForm = (
  props: ToRefs<{
    isOption: boolean
    isManager: boolean
    isFirstItem: boolean
    rentIncrease: IRentIncreaseTableForm | null | undefined
    commencementDate: string
    expiryDate: string
  }>,
) => {
  const { rentIncrease, commencementDate, isManager, isFirstItem } = props
  const instance = getCurrentInstance()
  const dayjs = useDayjs()
  const { dateFormat: divisionDateFormat } = useDivisions()

  // FORM REF
  const form = ref<{ node: FormKitNode }>()

  // RENT INCREASE METHODS
  const options = computed(() => {
    let options = RENT_INCREASE_TABLE_METHODS

    if (isManager.value) {
      options = options.filter(
        (opt) => opt.value !== IncreaseTableMethod.FORMULA,
      )
    }

    return options
  })

  // SUBMIT HANDLER
  const onSubmit = (data: IRentIncreaseTableForm) => {
    const _data: IRentIncreaseTableForm = { ...data }
    if (!_data.method) return

    // Remove private keys (checkboxes)
    for (const key in _data) {
      if (key.startsWith('_')) {
        delete _data[key as keyof IRentIncreaseTableForm]
      }
    }

    _data.date = formatDate(_data.date)

    if (_data.method === IncreaseTableMethod.MARKET && _data.marketReviewDate) {
      _data.marketReviewDate = formatDate(_data.marketReviewDate)
    }

    // Manager only
    if (isManager.value) {
      // If method is fixed amount or market, duplicate is always false
      if (
        rentIncrease.value ||
        !isFirstItem.value ||
        [IncreaseTableMethod.FIXED_AMOUNT, IncreaseTableMethod.MARKET].includes(
          _data.method,
        )
      ) {
        _data.duplicate = false
      }

      Object.defineProperty(_data, 'duplicate', {
        enumerable: false,
        value: _data.duplicate,
      })
    }

    instance?.emit('confirm', _data)
  }

  // INITIAL FORM VALUE
  const initialFormValue = (): Partial<IRentIncreaseTableForm> => {
    // CREATE
    if (!rentIncrease.value) {
      return {
        date:
          isManager.value && isFirstItem.value
            ? dayjs(commencementDate.value).add(1, 'year').toISOString()
            : undefined,
        method: IncreaseTableMethod.FIXED_PERCENT,
        duplicate: isManager.value && isFirstItem.value ? true : undefined,
      }
    }

    const rentIncreaseValue: IRentIncreaseTableForm = {
      ...rentIncrease.value,
      date: parseDate(rentIncrease.value.date),
    }

    if (rentIncreaseValue.method === IncreaseTableMethod.MARKET) {
      rentIncreaseValue.marketReviewDate = parseDate(
        rentIncreaseValue.marketReviewDate,
      )
    }

    // EDIT
    return {
      ...(rentIncreaseValue as Partial<IRentIncreaseTableForm>),
      _collarCap:
        // CPI
        !!rentIncreaseValue.cpiCollar ||
        !!rentIncreaseValue.cpiCap ||
        // Market
        !!rentIncreaseValue.marketReviewCollar ||
        !!rentIncreaseValue.marketReviewCap,
      _greaterOf: !!rentIncreaseValue.greaterOf,
      _lesserOf: !!rentIncreaseValue.lesserOf,
    }
  }

  return {
    form,
    options,
    onSubmit,
    initialFormValue,
  }

  function formatDate<T extends string | undefined>(date: T): T {
    if (!date) return undefined as T

    return (
      isManager.value
        ? dayjs(date).toISOString()
        : dayjs(date).format(divisionDateFormat.value)
    ) as T
  }

  function parseDate<T extends string | undefined>(date: T): T {
    if (!date) return undefined as T

    return (
      isManager.value
        ? dayjs(date).toISOString()
        : dayjs(date, divisionDateFormat.value).toISOString()
    ) as T
  }
}
