<script setup lang="ts">
import { createColumnHelper } from '@tanstack/vue-table'
import {
  type TanstackTableColumn,
  useTanstackTableVariants,
} from '@ui/components/TanstackTable'
import type { IOptionExerciseDetailsValue } from './types'

const props = withDefaults(
  defineProps<{
    data?: IOptionExerciseDetailsValue[] | string
    label?: string
    disabled?: boolean
    isRequired?: boolean
    isApproved?: boolean
    originalExpiryDate?: Date | string | null
    currentExpiryDate?: Date | string | null
    divisionDateFormat?: string
    leaseDateFormat: string
    emptyMessage?: string
    canExercise?: boolean
  }>(),
  {
    data: undefined,
    label: '',
    disabled: false,
    isRequired: false,
    isApproved: false,
    originalExpiryDate: undefined,
    currentExpiryDate: undefined,
    divisionDateFormat: undefined,
    emptyMessage: 'No option exercise details added',
    canExercise: false,
  },
)
defineEmits<{
  (event: 'edit', value: IOptionExerciseDetailsValue): void
  (event: 'exercise', value: IOptionExerciseDetailsValue): void
}>()

const dayjs = useDayjs()

const sortedItems = computed<IOptionExerciseDetailsValue[]>(() => {
  // The original value can be a string, so we need to convert it to an empty array.
  if (!props.data || !Array.isArray(props.data)) {
    return []
  }

  // We destruct the array, so we can sort without modifying the original. But we don't destruct its objects
  // in order to have the same reference and be able to find their indexes later.
  return [...props.data].sort((a, b) => {
    if (!a || !b) return 0

    // Convert the option values to numbers before comparison
    const optionA = parseInt(String(a), 10)
    const optionB = parseInt(String(b), 10)

    return optionB - optionA
  })
})

const formatDate = (date: string) => {
  if (!date) return 'N/A'
  return dayjs(date, props.divisionDateFormat).format(props.leaseDateFormat)
}

const expiryDateTooltip = computed(() => {
  const current = props.currentExpiryDate
  if (current && current !== props.originalExpiryDate)
    return `The original expiry date was ${dayjs(props.originalExpiryDate).format(props.leaseDateFormat)}`
  return undefined
})

const columnHelper = createColumnHelper<IOptionExerciseDetailsValue>()
const columns = [
  columnHelper.accessor('option', {
    header: 'Option',
  }),
  columnHelper.accessor('exerciseStartDate', {
    header: 'Exercise start date',
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor('exerciseEndDate', {
    header: 'Exercise end date',
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor('isExercised', {
    header: 'Exercised',
    cell: ({ getValue }) => {
      const value = getValue()

      if (value === 'True') return true
      if (value === 'False') return false

      return value
    },
  }),
  columnHelper.display({
    id: 'actions',
  }),
]

const variant = useTanstackTableVariants(
  {
    slots: {
      base: '',
    },
    variants: {
      rounded: {
        true: {
          base: 'border',
        },
      },
      isApproved: {
        true: {
          base: 'border-primary ring-[1px] ring-primary/70',
        },
        false: {
          base: 'border-gray-650',
        },
      },
    },
  },
  {
    get isApproved() {
      return props.isApproved
    },
  },
)
</script>

<template>
  <div class="mb-4">
    <h3
      class="mb-2 flex flex-auto justify-between text-sm font-medium text-gray-900 dark:text-white"
    >
      {{ label }}
      <span class="self-end px-2 text-xs">
        <Tooltip
          :content="expiryDateTooltip"
          class="border-gray-750 min-w-max border bg-gray-950 text-xs shadow-xl"
          placement="top"
          :outer-class="
            expiryDateTooltip &&
            'cursor-help hover:bg-gray-950 px-2 py-1 rounded-lg -mr-2 -mb-1'
          "
        >
          expiry date:
          <time>
            {{
              currentExpiryDate
                ? dayjs(currentExpiryDate).format(leaseDateFormat)
                : 'N/A'
            }}
          </time>
        </Tooltip>
      </span>
    </h3>

    <!-- TODO: Tanstack table cache is not updating, so we need to display the items (hidden) to force the cache to update -->
    <div class="hidden">
      {{ sortedItems }}
    </div>

    <TanstackTable
      :columns="columns"
      :data="[...sortedItems]"
      :variant="variant"
      :sticky="{ scroller: null }"
      rounded
    >
      <template #empty>
        <div
          class="flex items-center justify-center px-4 py-3 text-sm text-gray-500"
        >
          {{ emptyMessage }}
        </div>
      </template>

      <template #item-exerciseStartDate="{ value }">
        <time>{{ value }}</time>
      </template>

      <template #item-exerciseEndDate="{ value }">
        <time>{{ value }}</time>
      </template>

      <template
        #item-actions="{
          item: optionExerciseDetail,
        }: TanstackTableColumn<IOptionExerciseDetailsValue>"
      >
        <div
          v-if="!isApproved"
          class="flex justify-end gap-2 opacity-0 transition-all duration-100 group-hover/tr:opacity-100"
        >
          <button
            class="text-primary hover:bg-primary-600 flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg transition duration-150 hover:text-black"
            :class="[disabled && 'pointer-events-none']"
            @click.prevent="$emit('edit', optionExerciseDetail)"
          >
            <NuxtIcon name="edit" filled />
          </button>
        </div>
      </template>

      <template #item-isExercised="{ index, item }">
        <ReviewFormOptionExerciseDetailsExerciseButton
          :exercisable="canExercise"
          :is-first="index === 0"
          :is-last="index === sortedItems.length - 1"
          :item="item"
          :previous-item="sortedItems[index - 1]"
          :next-item="sortedItems[index + 1]"
          @exercise="$emit('exercise', $event)"
        />
      </template>

      <template #footer>
        <slot name="footer" />
      </template>
    </TanstackTable>
  </div>
</template>
