import { type INode, type INodeGroup, NodeGroupType } from '@manager'
import type { INodeDynamicComponentConfig } from './types'

export const GroupConfig: INodeDynamicComponentConfig = {
  glob: () => import.meta.glob('@manager/components/Group/**/*.vue'),
  directory: '/layers/manager/components/Group',
  path: (node) => ['Custom', node.name],
  defaultComponent: (node: INode) => {
    switch ((node as INodeGroup).type) {
      case NodeGroupType.SUB_GROUP:
        return 'SubGroup'
      case NodeGroupType.FIELD_SECTION:
        return 'FieldSection'
      case NodeGroupType.TABLE:
        return 'Table'
      case NodeGroupType.REPEATER:
        return 'Repeater'
      default:
        return 'Group'
    }
  },
}
