import { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { nanoid } from 'nanoid'
import { FetchError } from 'ofetch'
import { AuthRedirectCondition } from '~/composables/auth'

export function fetchAuthorizedManager<
  T = unknown,
  R extends NitroFetchRequest = NitroFetchRequest,
  O extends NitroFetchOptions<R> = NitroFetchOptions<R>,
>(request: R, options?: O) {
  const headers = useRequestHeaders(['cookie'])
  const { status, signIn } = useAuth()
  const { setRedirect } = useAuthRedirect()
  const route = useRoute()
  const apiURL = useRuntimeConfig().public.managerURL

  return $fetch<T, R, O>(request, {
    baseURL: apiURL,
    credentials: 'include',
    headers: {
      Cookie: headers.cookie,
      'Correlation-id': nanoid(),
      ...(options?.headers ?? {}),
    },
    ...((options ?? {}) as O),
  }).catch(async (e) => {
    if (e instanceof FetchError) {
      if (status.value === 'authenticated' && e.response?.status === 401) {
        // remember destination and redirect to login
        setRedirect({
          url: route.fullPath,
          condition: AuthRedirectCondition.Authenticated,
        })

        await signIn()
      }
    }

    return Promise.reject(e)
  })
}
