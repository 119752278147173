import type { ComputedRef, Ref } from 'vue'
import { getLeaseDateFormat } from '~/utils/getLeaseDateFormat'

import {
  groupClausesAndDefinitions,
  groupSpecialConditions,
  LeaseFieldType,
  LeaseFieldDataType,
  type ILeaseRegisterLease,
  type ILeaseDocumentField,
  type ILeaseDocumentFieldSection,
  type ILeaseDocumentFieldGroup,
  type ILeaseDocumentSpecialConditionsByKind,
  type ILeaseDocumentClausesAndDefinitionsGroup,
  type ILeaseSpecialCondition,
} from '@register'

/**
 * This store is used to manage the state of the lease review page.
 *
 * **It should be used only in the lease review page.**
 */

type LeaseStoreReturn = {
  // LEASE
  lease: Ref<ILeaseRegisterLease | undefined>
  documentName: ComputedRef<string | undefined>
  dateFormat: ComputedRef<string | undefined>
  groups: ComputedRef<ILeaseDocumentFieldGroup[]>
  sections: ComputedRef<ILeaseDocumentFieldSection[]>
  fields: ComputedRef<ILeaseDocumentField[]>
  availableClausesAndDefinitions: ComputedRef<
    ILeaseDocumentClausesAndDefinitionsGroup | undefined
  >
  clausesAndDefinitions: ComputedRef<
    ILeaseDocumentClausesAndDefinitionsGroup | undefined
  >
  specialConditions: ComputedRef<ILeaseDocumentSpecialConditionsByKind>
  specialConditionFields: ComputedRef<ILeaseSpecialCondition[]>
  // FAST APPROVAL
  fastApproval: Ref<boolean>
  // SHOW PROGRESS
  showProgress: Ref<boolean>
  toggleAddressMap: (id: string) => void
}

export const useLeaseStore = defineStore('lease', (): LeaseStoreReturn => {
  const showProgress = ref(false)

  /* === LEASE === */
  // We initialize it in the lease page once.
  const lease = ref<ILeaseRegisterLease>()
  // The name of the document to be displayed in the header
  const documentName = computed(() => {
    return (
      lease.value?.name ??
      lease.value?.importDocument?.filename ??
      lease.value?.importDocument?.outputFilename ??
      undefined
    )
  })

  // Date format for this lease
  const dateFormat = computed(() => getLeaseDateFormat(lease.value))

  //==================================================================

  function createAddressMapField(
    field: ILeaseDocumentField,
  ): ILeaseDocumentField {
    return {
      id: field.id + 'Map',
      name: field.name + ' Map',
      isRequired: true,
      fieldType: LeaseFieldType.FIELD,
      dataType: LeaseFieldDataType.OBJECT,
      isMultiLine: false,
      isActive: true,
      description: 'Google Maps',
      typeDefinition: {
        name: 'GoogleMapsModel',
      },
    }
  }

  const _showingMaps = ref<string[]>([])

  function toggleAddressMap(id: string) {
    const index = _showingMaps.value.indexOf(id)

    if (index !== -1) {
      // If the value is in the array, remove it using splice
      _showingMaps.value.splice(index, 1)
    } else {
      // If the value is not in the array, add it
      _showingMaps.value.push(id)
    }
  }

  //==================================================================
  // The groups of the lease document, in a flat array
  const groups = computed<ILeaseDocumentFieldGroup[]>(() => {
    return (lease.value?.groups || []).map((group) => {
      //we need to add a field of google maps manually to each field that contains address
      return {
        ...group,
        sections: (group.sections || []).map((section) => {
          // search for the address field
          const index = section.fields?.findIndex(
            (field) => field.typeDefinition?.name === 'AddressModel',
          )

          if (section.fields && index !== undefined && index !== -1) {
            const field = section.fields[index]

            if (field && _showingMaps.value.includes(field.id)) {
              const updatedFields = [...(section.fields || [])]
              //add the google maps field
              updatedFields.splice(index + 1, 0, createAddressMapField(field))

              // Update the section's fields with the new array
              return {
                ...section,
                fields: updatedFields,
              }
            }
          }
          return section
        }),
      }
    })
  })

  //==================================================================
  // The sections of the lease document, in a flat array
  const sections = computed<ILeaseDocumentFieldSection[]>(() =>
    (groups.value.flatMap((group) => group.sections) ?? []).filter(
      (section): section is ILeaseDocumentFieldSection => !!section,
    ),
  )

  //==================================================================
  // The fields of the lease document, in a flat array
  const fields = computed(() =>
    (sections.value.flatMap((section) => section.fields) ?? []).filter(
      (field): field is ILeaseDocumentField => !!field,
    ),
  )

  //==================================================================
  // The available clauses and definitions of the lease document, grouped by id
  const availableClausesAndDefinitions = computed(() =>
    groupClausesAndDefinitions(
      lease.value?.clausesAndDefinitions?.available ?? [],
    ),
  )

  //==================================================================
  // The clauses and definitions of the lease document, grouped by id
  const clausesAndDefinitions = computed(() =>
    groupClausesAndDefinitions(lease.value?.clausesAndDefinitions?.table ?? []),
  )

  //==================================================================
  // The special conditions of the lease document, grouped by id
  const specialConditions = computed<ILeaseDocumentSpecialConditionsByKind>(
    () => {
      const result: ILeaseDocumentSpecialConditionsByKind =
        {} as ILeaseDocumentSpecialConditionsByKind

      // for (const kind in lease.value?.specialConditions ?? {}) {
      //   // TODO: fix types
      //   result[kind] = groupSpecialConditions(
      //     lease.value?.specialConditions?.[kind] ?? [],
      //   )
      // }
      for (const kind in lease?.value?.specialConditions ?? {}) {
        if (
          lease?.value?.specialConditions &&
          kind in lease.value.specialConditions
        ) {
          result[kind as keyof ILeaseDocumentSpecialConditionsByKind] =
            groupSpecialConditions(
              lease.value.specialConditions[
                kind as keyof ILeaseDocumentSpecialConditionsByKind
              ] ?? [],
            )
        }
      }

      return result as ILeaseDocumentSpecialConditionsByKind
    },
  )
  const specialConditionFields = computed(() => {
    return Object.values(lease?.value?.specialConditions ?? {})
      .flat()
      .filter((sp): sp is ILeaseSpecialCondition => Boolean(sp))
  })

  /* === LEASE === */

  /* === FAST APPROVAL === */
  const fastApproval = ref(false)
  /* === FAST APPROVAL === */

  return {
    // LEASE
    lease,
    documentName,
    dateFormat,
    groups,
    sections,
    fields,
    availableClausesAndDefinitions,
    clausesAndDefinitions,
    specialConditions,
    specialConditionFields,
    // FAST APPROVAL
    fastApproval,
    // SHOW PROGRESS
    showProgress,
    toggleAddressMap,
  }
})
