<script setup lang="ts">
import formkitTheme from '@ui/formkit/formkit.theme'
import type {
  ListboxFormat,
  ListboxOptionsItem,
} from '@ui/components/Form/Listbox'
import {
  useOptions,
  useSyncValues,
} from '@ui/components/Form/Listbox/composables'

/* === PROPS === */
interface TaglistProps {
  options: ListboxOptionsItem[]
  labelBy?: string
  trackBy?: string
  format?: ListboxFormat
  modelValue?: any
  slots?: Record<string, any>
  // Classes
  taglistClass?: string
  taggeditemClass?: string
  taggeditemInnerClass?: string
  taggeditemRemoveClass?: string
}
const props = withDefaults(defineProps<TaglistProps>(), {
  options: () => [],
  labelBy: 'label',
  trackBy: 'value',
  format: 'value',
  modelValue: undefined,
  slots: () => ({}),
  // Classes
  taglistClass: formkitTheme.taglist.taglist,
  taggeditemClass: formkitTheme.taglist.taggeditem,
  taggeditemInnerClass: formkitTheme.taglist.taggeditemInner,
  taggeditemRemoveClass: formkitTheme.taglist.taggeditemRemove,
})

const {
  options,
  labelBy,
  trackBy,
  format,
  //Classes
  taglistClass,
  taggeditemClass,
  taggeditemInnerClass,
  taggeditemRemoveClass,
} = toRefs(props)
const multiple = ref(true)
/* === PROPS === */

/* === EMITS === */
const emit = defineEmits(['update:modelValue'])
/* === EMITS === */

/* === OPTIONS === */
const { getLabel, getTrack, toValue, toOption, compare } = useOptions({
  options,
  labelBy,
  trackBy,
  format,
  multiple,
})
/* === OPTIONS === */

/* === VALUE === */
const modelValue = useVModel(props, 'modelValue', emit)
const innerValue = ref<ListboxOptionsItem[]>(
  toOption(modelValue.value) as ListboxOptionsItem[],
)
useSyncValues(
  { innerValue, modelValue, format, options, multiple },
  { toValue, toOption },
)
/* === VALUE === */

const onRemove = (option: ListboxOptionsItem) => {
  const idx = innerValue.value.findIndex((o) => compare(o, option))
  if (idx !== -1) {
    const newValue = [...innerValue.value]
    newValue.splice(idx, 1)
    innerValue.value = newValue
  }
}
</script>

<template>
  <ul :class="taglistClass">
    <li
      v-for="option in innerValue"
      :key="getTrack(option)"
      :class="taggeditemClass"
    >
      <span :class="taggeditemInnerClass">
        <component
          :is="slots.taggeditem"
          v-if="slots.taggeditem"
          :option="option"
          :label="getLabel(option)"
        />
        <slot
          v-else
          name="taggeditem"
          :option="option"
          :label="getLabel(option)"
        >
          {{ getLabel(option) }}
        </slot>
      </span>
      <span
        role="button"
        tabindex="0"
        :class="taggeditemRemoveClass"
        @click="onRemove(option)"
        @keyup.enter="onRemove(option)"
      >
        <NuxtIcon name="close" />
      </span>
    </li>
  </ul>
</template>
