<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useUploadStore } from '~/stores/upload'

const uploadStore = useUploadStore()

const isUploading = computed(() => {
  return (
    uploadStore.files.uploading.length > 0 ||
    uploadStore.files.error.length > 0 ||
    uploadStore.files.finished.length > 0
  )
})

const isUploadingWithoutError = computed(() => {
  return (
    uploadStore.files.uploading.length > 0 &&
    uploadStore.files.error.length === 0
  )
})

function handleBeforeUnload(e: any) {
  if (uploadStore.files.uploading.length > 0) {
    e.returnValue = ''
    e.preventDefault()
  }
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload)
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
})

function onClickMenu() {
  useAnalytics({
    name: 'Navigation: Open Upload Progress',
    showDivision: true,
  })
}
</script>

<template>
  <Tooltip
    v-if="!isUploading"
    placement="bottom"
    content="There is no active upload"
    class="border-gray-750 border bg-gray-900 font-medium text-white"
    outer-class="hidden md:block"
  >
    <div
      class="nav-icon hover:bg-primary focus:bg-primary relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full text-white opacity-30 transition-colors hover:text-black md:flex"
    >
      <NuxtIcon name="upload" class="text-2xl" filled />
    </div>
  </Tooltip>

  <Popover v-else class="relative">
    <PopoverButton
      class="group inline-flex rounded-md focus:outline-none"
      @click="onClickMenu"
    >
      <div
        class="nav-icon relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full text-white transition-colors"
        :class="[
          uploadStore.files.error.length > 0
            ? 'bg-red-500 text-red-50 hover:bg-red-600 hover:text-red-100'
            : 'hover:bg-primary bg-opacity-5 hover:text-black',
        ]"
      >
        <div
          v-if="isUploadingWithoutError"
          class="lds-ring pointer-events-none absolute left-0 top-0"
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <NuxtIcon name="upload" class="text-2xl" filled />
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-in"
      enter-from-class="translate-y-2 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-out"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute right-0 z-10 mt-3 min-h-[160px] max-w-[90vw] rounded-lg border border-neutral-200 bg-neutral-100 shadow-lg md:w-[400px] md:max-w-[500px] dark:border-[#323235] dark:bg-[#28282a]"
      >
        <Scrollbar class="max-h-[70vh]">
          <div class="flex flex-col">
            <!-- ERRORS -->
            <div v-if="uploadStore.files.error.length > 0">
              <h2
                class="sticky top-0 z-10 overflow-hidden rounded-t-lg bg-neutral-200/60 px-4 py-1 text-xl font-semibold backdrop-blur-md backdrop-saturate-[180%] dark:bg-[#212225]/50"
              >
                Error:
              </h2>

              <div class="gap divide-y-1 px-4">
                <div
                  v-for="item in uploadStore.files.error"
                  :key="item.company.id"
                  class="py-2"
                >
                  <h3 class="text-base font-bold">{{ item.company.name }}</h3>

                  <div
                    v-for="file in item.files"
                    :key="file.file.id"
                    class="group"
                  >
                    <div class="py-2 text-xs font-medium">
                      <div class="flex justify-between dark:text-white">
                        <span class="truncate font-semibold">
                          {{ file.file.name }}
                        </span>
                        <div class="flex gap-1">
                          <span
                            class="-mt-1 mb-1 cursor-pointer select-none rounded-md px-2 py-1 text-red-500 transition-all hover:bg-red-50 group-hover:inline-block"
                            role="button"
                            tabindex="0"
                            @click="
                              uploadStore.removeFile(
                                item.company.id,
                                file.file.id,
                              )
                            "
                            @keyup.enter="
                              uploadStore.removeFile(
                                item.company.id,
                                file.file.id,
                              )
                            "
                          >
                            remove
                          </span>

                          <span
                            class="bg-primary text-primary-50 hover:bg-primary-400 -mt-1 mb-1 cursor-pointer select-none rounded-md px-2 py-1 transition-all group-hover:inline-block"
                            role="button"
                            tabindex="0"
                            @click="
                              uploadStore.retryFile(
                                item.company.id,
                                file.file.id,
                              )
                            "
                            @keyup.enter="
                              uploadStore.retryFile(
                                item.company.id,
                                file.file.id,
                              )
                            "
                          >
                            retry
                          </span>
                        </div>
                      </div>
                      <div
                        class="flex w-full rounded-lg bg-red-500 font-medium text-red-50"
                      >
                        <Tooltip
                          placement="left"
                          :content="`${
                            file.error?.message || 'Unknown error'
                          } on file '${file.file.name}'`"
                          class="bg-red-100 font-medium text-red-500"
                          outer-class="w-full"
                        >
                          <div class="whitespace-pre- px-2">
                            {{ file.error?.message || file.error }}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- UPLOADING -->
            <div v-if="uploadStore.files.uploading.length > 0">
              <h2
                class="sticky top-0 z-10 overflow-hidden rounded-t-lg bg-neutral-200/60 px-4 py-1 text-xl font-semibold backdrop-blur-md backdrop-saturate-[180%] dark:bg-[#212225]/50"
              >
                Uploading:
              </h2>

              <div class="gap divide-y-1 px-4">
                <div
                  v-for="item in uploadStore.files.uploading"
                  :key="item.company.id"
                  class="py-2"
                >
                  <h3 class="text-base font-bold">{{ item.company.name }}</h3>

                  <div
                    v-for="file in item.files"
                    :key="file.file.id"
                    class="group"
                  >
                    <div class="py-2 text-xs font-medium">
                      <div class="flex justify-between dark:text-white">
                        <span class="truncate font-semibold">
                          {{ file.file.name }}
                        </span>
                        <span
                          class="-mt-1 mb-1 cursor-pointer select-none rounded-md px-2 py-1 text-red-500 transition-all hover:bg-red-50 group-hover:inline-block"
                          role="button"
                          tabindex="0"
                          @click="
                            uploadStore.removeFile(
                              item.company.id,
                              file.file.id,
                            )
                          "
                          @keyup.enter="
                            uploadStore.removeFile(
                              item.company.id,
                              file.file.id,
                            )
                          "
                        >
                          remove
                        </span>
                      </div>
                      <div
                        class="w-full rounded-lg bg-gray-200 dark:bg-gray-700"
                      >
                        <div
                          class="bg-primary h-4 rounded-lg text-center text-black"
                          :style="`width: ${file.progress}%`"
                        >
                          <span
                            class="whitespace-pre text-black"
                            :class="{
                              'pl-2': file.progress < 10,
                            }"
                          >
                            {{ file.progress }} %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- FINISHED -->

            <div v-if="uploadStore.files.finished.length > 0">
              <h2
                class="sticky top-0 z-10 overflow-hidden rounded-t-lg bg-neutral-200/60 px-4 py-1 text-xl font-semibold backdrop-blur-md backdrop-saturate-[180%] dark:bg-[#212225]/50"
              >
                Finished:
              </h2>

              <div class="gap divide-y-1 px-4">
                <div
                  v-for="item in uploadStore.files.finished"
                  :key="item.company.id"
                  class="py-2"
                >
                  <h3 class="text-base font-bold">{{ item.company.name }}</h3>

                  <div
                    v-for="file in item.files"
                    :key="file.file.id"
                    class="group"
                  >
                    <div class="py-2 text-xs font-medium">
                      <div class="flex justify-between dark:text-white">
                        <span class="truncate font-semibold">
                          {{ file.file.name }}
                        </span>
                        <div class="flex gap-1">
                          <span
                            class="hidden- hover:bg-primary -mt-1 mb-1 cursor-pointer select-none rounded-md px-2 py-1 transition-all hover:text-black group-hover:inline-block"
                            role="button"
                            tabindex="0"
                            @click="
                              uploadStore.removeFile(
                                item.company.id,
                                file.file.id,
                              )
                            "
                            @keyup.enter="
                              uploadStore.removeFile(
                                item.company.id,
                                file.file.id,
                              )
                            "
                          >
                            clear
                          </span>
                        </div>
                      </div>
                      <div
                        class="bg-primary flex h-4 w-full rounded-lg px-2 text-black"
                      >
                        Upload completed
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<style lang="postcss" scoped>
.lds-ring div {
  @apply border-t-primary absolute box-border block h-10 w-10 rounded-full border-4 border-gray-700;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
