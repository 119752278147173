// Generated by @nuxtjs/dayjs
import dayjs from 'dayjs'




import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)
import isTomorrow from 'dayjs/plugin/isTomorrow'
dayjs.extend(isTomorrow)
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(isoWeek)
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

