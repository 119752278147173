import { SearchScope } from '@portfolio'
import { exhaustiveMatchingGuard } from '~/utils/exhaustiveMatchingGuard'

export function localizeSearchScope(scope: SearchScope) {
  if (scope === ('[all]' as SearchScope)) scope = SearchScope.ALL

  switch (scope) {
    case SearchScope.ALL:
      return 'Show all'
    case SearchScope.HEAD_LEASES:
      return 'Head leases'
    case SearchScope.SUB_LEASES:
      return 'Sub leases'
    default:
      return exhaustiveMatchingGuard(scope)
  }
}
