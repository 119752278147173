<script setup lang="ts">
import VueMultiselect from 'vue-multiselect'
import type {
  ListboxInnerValue,
  ListboxOptionsItem,
  ListboxProps,
} from './types'
import formkitTheme from '@ui/formkit/formkit.theme'
import { useOptions, useDisplayValue, useSyncValues } from './composables'

/* === PROPS === */
const props = withDefaults(defineProps<ListboxProps>(), {
  options: () => [],
  labelBy: 'label',
  trackBy: 'value',
  format: 'value',
  searchable: (props) => !!props.taggable,
  multiple: (props) => !!props.taggable,
  taggable: false,
  disabled: false,
  placeholder: 'Select an option',
  emptyMessage: 'No options available',
  modelValue: undefined,
  loading: false,
  hideSelected: false,
  closeOnSelect: true,
  internalSearch: (props) => !!props.searchable,
  hideDisplayValue: false,
  customLabel: undefined,
  allowEmpty: (props) => !!props.multiple,
  max: undefined,
  slots: () => ({}),
  // Classes
  inputOuterClass: formkitTheme.listbox.inputOuter,
  inputInnerClass: formkitTheme.listbox.inputInner,
  buttonInnerClass: formkitTheme.listbox.buttonInner,
  buttonIconClass: formkitTheme.listbox.buttonIcon,
  dropdownWrapperClass: formkitTheme.listbox.dropdownWrapper,
  listboxClass: formkitTheme.listbox.listbox,
  emptyMessageClass: formkitTheme.listbox.emptyMessage,
  emptyMessageInnerClass: formkitTheme.listbox.emptyMessageInner,
  listitemClass: formkitTheme.listbox.listitem,
  listitemInnerClass: formkitTheme.listbox.listitemInner,
  loadMoreClass: formkitTheme.listbox.loadMore,
  loadMoreInnerClass: formkitTheme.listbox.loadMoreInner,
})

const {
  placeholder,
  emptyMessage,
  searchable,
  options,
  labelBy,
  trackBy,
  format,
  multiple,
  taggable,
  disabled,
  loading,
  hideSelected,
  closeOnSelect,
  internalSearch,
  hideDisplayValue,
  customLabel,
  allowEmpty,
  max,
  //Classes
  inputOuterClass,
  inputInnerClass,
  buttonInnerClass,
  buttonIconClass,
  dropdownWrapperClass,
  listboxClass,
  emptyMessageClass,
  emptyMessageInnerClass,
  listitemClass,
  listitemInnerClass,
  loadMoreClass,
  loadMoreInnerClass,
} = toRefs(props)
/* === PROPS === */

/* === EMITS ===*/
const emit = defineEmits([
  'update:modelValue',
  'search',
  'tag',
  'select',
  'remove',
  'open',
  'close',
])
/* === EMITS ===*/

/* === OPTIONS === */
const { getLabel, toValue, toOption, compare } = useOptions({
  options,
  multiple,
  labelBy,
  trackBy,
  format,
  customLabel,
})
/* === OPTIONS === */

/* === VALUE === */
const modelValue = useVModel(props, 'modelValue', emit)
const innerValue = ref<ListboxInnerValue>(toOption(modelValue.value))
useSyncValues(
  { innerValue, modelValue, multiple, format, options },
  { toValue, toOption },
)

const displayValue = useDisplayValue(
  { placeholder, multiple, searchable },
  { getLabel },
)
/* === VALUE === */

/* === TAGGABLE === */
const onTag = (searchQuery: string, id: string) => {
  const customOption = {
    [trackBy.value]: searchQuery,
    [labelBy.value]: searchQuery,
  } satisfies ListboxOptionsItem

  const hasValue = (innerValue.value as ListboxOptionsItem[]).find((option) =>
    compare(option, customOption),
  )

  if (!hasValue) {
    innerValue.value = (innerValue.value as ListboxOptionsItem[]).concat(
      customOption,
    )
    emit('tag', searchQuery, id)
  }
}
/* === TAGGABLE === */

// REFS
const multiselect = ref<InstanceType<typeof VueMultiselect> | null>(null)

// COMPUTED
const hasSelection = computed(() => {
  return multiple.value
    ? (innerValue.value as ListboxOptionsItem[]).length > 0
    : innerValue.value !== undefined
})

// EVENTS
const onSelect = (selectedOption: ListboxOptionsItem, id: string) =>
  emit('select', selectedOption, id)
const onRemove = (removedOption: ListboxOptionsItem, id: string) =>
  emit('remove', removedOption, id)
const onSearchChange = (searchQuery: string, id: string) =>
  emit('search', searchQuery, id)
const onOpen = (id: string) => emit('open', id)
const onClose = (value: any, id: string) => emit('close', value, id)

// PROGRAMMATIC METHODS
defineExpose({
  activate: () => multiselect.value?.activate(),
  deactivate: () => multiselect.value?.deactivate(),
  focus: () => multiselect.value?.focus(),
})
</script>

<template>
  <VueMultiselect
    ref="multiselect"
    v-model="innerValue"
    :disabled="disabled"
    :loading="loading"
    :options="options"
    :track-by="trackBy"
    :label="labelBy"
    :placeholder="placeholder"
    :multiple="multiple"
    :allow-empty="allowEmpty"
    :searchable="searchable"
    :internal-search="internalSearch"
    :taggable="taggable"
    :hide-selected="hideSelected"
    :max="max"
    :close-on-select="closeOnSelect"
    :clear-on-select="true"
    :show-labels="false"
    @select="onSelect"
    @remove="onRemove"
    @search-change="onSearchChange"
    @tag="onTag"
    @open="onOpen"
    @close="onClose"
  >
    <template #caret>
      <div class="multiselect__select">
        <NuxtIcon name="chevron-down" />
      </div>
    </template>

    <template v-if="multiple" #selection="{ remove, isOpen }">
      <component
        :is="slots.selection"
        v-if="slots.selection"
        :value="innerValue"
        :display-value="displayValue(innerValue)"
        :remove="remove"
        :is-open="isOpen"
      />
      <slot
        name="selection"
        :value="innerValue"
        :display-value="displayValue(innerValue)"
        :remove="remove"
        :is-open="isOpen"
      >
        <template v-if="hasSelection">
          <template v-if="!hideDisplayValue">
            <span
              v-for="value in innerValue"
              :key="value"
              class="-mt-0.5 mb-2 mr-2 inline-flex items-center rounded-full bg-gray-700 px-2 py-0.5"
            >
              <span :class="buttonInnerClass">
                {{ getLabel(value) || 'N/A' }}
              </span>
              <Button
                icon="close"
                color="transparentPrimary"
                size="xs"
                class="-mr-1.5 ml-1 size-5 rounded-full"
                @click="remove(value)"
              />
            </span>
          </template>
          <span
            v-show="hideDisplayValue && !isOpen"
            class="multiselect__placeholder"
          >
            {{ placeholder }}
          </span>
        </template>
      </slot>
    </template>

    <template v-else #singleLabel>
      <component
        :is="slots.selection"
        v-if="slots.selection"
        :value="innerValue"
        :display-value="getLabel(innerValue as ListboxOptionsItem)"
      />
      <slot
        name="selection"
        :value="innerValue"
        :display-value="getLabel(innerValue as ListboxOptionsItem)"
      >
        <span :class="buttonInnerClass">
          {{ getLabel(innerValue as ListboxOptionsItem) }}
        </span>
      </slot>
    </template>

    <template #noOptions>
      <span :class="emptyMessageClass">
        <component
          :is="slots.emptyMessage"
          v-if="slots.emptyMessage"
          :empty-message="emptyMessage"
        />
        <slot v-else name="emptyMessage" :empty-message="emptyMessage">
          <span :class="emptyMessageInnerClass">
            {{ emptyMessage }}
          </span>
        </slot>
      </span>
    </template>

    <template #noResult>
      <span :class="emptyMessageClass">
        <component
          :is="slots.emptyMessage"
          v-if="slots.emptyMessage"
          :empty-message="emptyMessage"
        />
        <slot v-else name="emptyMessage" :empty-message="emptyMessage">
          <span :class="emptyMessageInnerClass">
            {{ emptyMessage }}
          </span>
        </slot>
      </span>
    </template>

    <!-- List box item -->
    <template #option="{ option }">
      <component
        :is="slots.listitem"
        v-if="slots.listitem"
        :option="option"
        :label="getLabel(option)"
      />
      <slot v-else name="listitem" :option="option" :label="getLabel(option)">
        <span :class="listitemInnerClass">
          {{ getLabel(option) }}
        </span>
      </slot>
    </template>

    <!-- Load more -->
    <template #afterList>
      <li v-if="loading" :class="['multiselect__element', loadMoreClass]">
        <component :is="slots.loadMore" v-if="slots.loadMore" />
        <slot v-else name="loadMore">
          <span :class="loadMoreInnerClass"> Loading... </span>
        </slot>
      </li>
    </template>
  </VueMultiselect>
</template>

<style lang="postcss">
.multiselect {
  fieldset[disabled] .multiselect {
    @apply pointer-events-none;
  }

  &[data-invalid] .multiselect {
    .multiselect__tags,
    .multiselect__content-wrapper {
      @apply z-[101] border-danger ring-danger;
    }

    .multiselect__tag {
      @apply text-white;
    }

    .multiselect__placeholder,
    .multiselect__input::placeholder {
      @apply text-danger;
    }

    &:not(.multiselect--active) {
      @apply text-danger;

      .multiselect__content-wrapper {
        @apply z-[101];
      }
    }
  }

  .multiselect__spinner {
    @apply absolute right-6 top-[1px] block h-[42px] w-12 rounded-lg bg-inherit sm:h-[40px];

    &::before,
    &::after {
      @apply absolute left-[50%] top-[50%] -ml-2 -mt-2 mb-0 mr-0 h-4 w-4 rounded-lg border-2 border-solid border-x-transparent border-b-transparent border-t-primary-500 shadow-[0_0_0_1px_transparent] content-[""];
    }

    &::before {
      @apply animate-spin;
    }

    &::after {
      @apply animate-spin;
    }
  }

  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    @apply opacity-100 transition-opacity duration-300 ease-in-out;
  }

  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    @apply opacity-0;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    @apply touch-manipulation sm:text-sm;

    font-family: inherit;
  }

  .multiselect {
    @apply relative box-content block min-h-full w-full text-left;

    * {
      @apply box-border;
    }

    &:focus {
      @apply outline-none;
    }
  }

  .multiselect--disabled {
    @apply pointer-events-none;

    .multiselect__tags {
      @apply bg-transparent;
    }
  }

  .multiselect--active {
    @apply z-50;

    .multiselect__tags {
      /**/
    }

    .multiselect__select {
      transform: rotateZ(180deg);
    }
  }

  /* TODO input placeholder padding */

  .multiselect__input,
  .multiselect__single {
    @apply relative mb-2 box-border inline-block min-h-[20px] w-full rounded-lg border-none bg-transparent p-0 align-top leading-5 transition duration-100;
  }

  /* TODO: Placeholder color */
  .multiselect__input::placeholder {
    @apply text-gray-400;
  }

  .multiselect__tag ~ {
    .multiselect__input,
    .multiselect__single {
      @apply w-auto;
    }
  }

  .multiselect__input:hover,
  .multiselect__single:hover {
    @apply border-gray-300;
  }

  .multiselect__input:focus {
    @apply border-gray-300 outline-none ring-0;
  }

  /* TODO: Single select padding */
  .multiselect__single {
    &:focus {
      @apply border-gray-400 outline-none;
    }

    @apply mb-2;
  }

  .multiselect__tags-wrap {
    @apply inline;
  }

  .multiselect__tags {
    @apply block min-h-[40px] rounded-md border-none pb-0 pl-2.5 pr-10 pt-[11px] transition duration-100 sm:pt-2.5 sm:text-sm;
  }

  .multiselect__tag {
    @apply relative mb-[4px] mr-3 inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-primary-500 py-[3px] pl-3 pr-7 leading-none text-black transition duration-100;
  }

  .multiselect__tag-icon {
    @apply absolute bottom-0 right-0 top-0 ml-2 w-6 cursor-pointer rounded-lg text-center font-bold leading-[21px] transition-all duration-100;
    font-style: initial;

    &::after {
      @apply text-sm text-gray-700;

      content: '×';
    }

    &:focus::after,
    &:hover::after {
      @apply text-white;
    }
  }

  .multiselect__current {
    @apply m-0 box-border block min-h-[42px] cursor-pointer overflow-hidden whitespace-nowrap rounded-lg border border-solid border-gray-100 px-3 pb-0 pt-2 leading-4 no-underline sm:min-h-[40px];
  }

  .multiselect__select {
    @apply absolute right-[1px] top-[1px] m-0 box-border flex h-[42px] w-10 cursor-pointer items-center justify-center rounded-lg bg-inherit px-2 py-1 text-center leading-4 no-underline transition-transform duration-100 sm:h-[40px];

    /* &::before {
      @apply relative right-0 top-[65%] mt-1 border-x-[5px] border-b-0 border-t-[5px] border-solid border-transparent border-t-gray-400 text-gray-400 content-[""];
    }*/
  }

  /* TODO: Change placholder padding */
  .multiselect__placeholder {
    @apply mb-2 inline-block p-0 align-top leading-5 text-gray-400 transition duration-100;
  }

  .multiselect--active .multiselect__placeholder {
    @apply hidden;
  }

  .multiselect__content-wrapper {
    @apply absolute z-50 my-2 block max-h-[240px] w-full overflow-auto scroll-smooth rounded-lg border border-gray-500 dark:bg-gray-800;

    -webkit-overflow-scrolling: touch;
  }

  .multiselect__content {
    @apply m-0 inline-block min-w-full list-none p-0 align-top;
  }

  .multiselect--above .multiselect__content-wrapper {
    @apply bottom-full rounded-lg;
  }

  .multiselect__content::-webkit-scrollbar {
    @apply hidden;
  }

  .multiselect__element {
    @apply block;
  }

  .multiselect__option {
    @apply relative block min-h-[42px] cursor-pointer whitespace-nowrap p-3 align-middle text-sm normal-case leading-4 text-gray-200 no-underline sm:min-h-[40px];

    &::after {
      @apply absolute right-0 top-0 pl-5 pr-3 text-sm leading-10;
    }
  }

  :not(.multiselect-group) .multiselect__option--highlight {
    @apply text-white outline-none dark:bg-gray-700;

    &::after {
      @apply bg-gray-700 text-white content-[attr(data-select)];
    }
  }

  .multiselect-group .multiselect__option--highlight {
    @apply bg-gray-700 text-white outline-none;

    &::after {
      @apply bg-gray-700 text-white content-[attr(data-select)];
    }
  }

  .multiselect__option--selected {
    @apply text-white dark:bg-gray-750;

    &::after {
      @apply text-gray-300 content-[attr(data-select)];
    }

    &.multiselect__option--highlight {
      @apply bg-gray-700 text-white;

      &::after {
        @apply bg-gray-700 text-white content-[attr(data-select)];
      }
    }
  }

  .multiselect--disabled {
    .multiselect__current,
    .multiselect__select {
      @apply text-gray-400;
    }
  }

  .multiselect__option--disabled {
    @apply pointer-events-none cursor-text !bg-gray-200 !text-gray-400;
  }

  .multiselect__option--group {
    @apply bg-gray-200 text-primary-500;

    &.multiselect__option--highlight {
      @apply bg-gray-700 text-white;

      &::after {
        @apply bg-gray-700;
      }
    }
  }

  .multiselect__option--disabled.multiselect__option--highlight {
    @apply bg-gray-200;
  }

  .multiselect__option--group-selected.multiselect__option--highlight {
    @apply text-danger;

    &::after {
      @apply text-danger content-[attr(data-select)];
    }
  }

  /* Disable transition */
  /*.multiselect-enter-active,
  .multiselect-leave-active {
    @apply transition-all;
  }*/

  .multiselect-enter,
  .multiselect-leave-active {
    @apply opacity-0;
  }

  .multiselect__strong {
    @apply mb-2 inline-block align-top leading-5;
  }

  *[dir='rtl'] {
    .multiselect {
      @apply text-right;
    }

    .multiselect__select {
      @apply left-[1px] right-auto;
    }

    .multiselect__tags {
      @apply pb-0 pl-10 pr-2 pt-2;
    }

    .multiselect__content {
      @apply text-right;
    }

    .multiselect__option::after {
      @apply left-0 right-auto;
    }

    .multiselect__clear {
      @apply left-3 right-auto;
    }

    .multiselect__spinner {
      @apply left-[1px] right-auto;
    }
  }
}
</style>
