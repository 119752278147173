<script setup lang="ts">
import md5Hex from 'md5-hex'
import { LiteralUnion } from 'type-fest'
import { twMerge } from 'tailwind-merge'

interface Props {
  email: string
  name?: string
  class?: string
}
const props = withDefaults(defineProps<Props>(), {
  email: '',
  name: undefined,
  class: '',
})

interface Options {
  readonly size?: number
  readonly default?: LiteralUnion<
    '404' | 'mm' | 'identicon' | 'monsterid' | 'wavatar' | 'retro' | 'blank',
    string
  >
  readonly rating?: 'g' | 'pg' | 'r' | 'x'
}

const getInitials = (name: string): string => {
  const namePart = name.split('@')[0]

  // Extracting the first and last name from the name part
  const names = namePart.split(/[._ +\-!@#$%^&*()={}[\]|\\:;<,>.?/~`]/)

  // Filtering out empty strings and numbers
  const filteredNames = names.filter(
    (name) => isNaN(Number(name)) && name.length >= 2,
  )

  // Extracting the initials from the first and last names
  const initials = filteredNames
    .map((name, index) => {
      const sanitizedName = name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      // Check if the current character is a number and the next character is a letter
      if (
        sanitizedName.length > 1 &&
        /\d/.test(sanitizedName[0]) &&
        /[a-zA-Z]/.test(sanitizedName[1])
      ) {
        // If the next character is a letter, return the next character
        return sanitizedName[1].toUpperCase()
      } else {
        // Otherwise, return the first character
        return sanitizedName[0].toUpperCase()
      }
    })
    .join('')

  // If there are more than 2 initials, consider only the first and last initials
  if (initials.length > 2) {
    return initials[0] + initials[initials.length - 1]
  }

  return initials
}

function gravatarUrl(identifier: string): string | undefined {
  const options: Options = {
    // default: 'retro',
    default: 'blank',
  }

  if (!identifier) {
    return
  }

  if (identifier.includes('@')) {
    identifier = identifier.toLowerCase().trim()
  }

  const baseUrl = new URL('https://gravatar.com/avatar/')
  baseUrl.pathname += md5Hex(identifier)
  if (options) {
    baseUrl.search = new URLSearchParams(
      options as Record<string, string>,
    ).toString()
  }
  return baseUrl.toString()
}
const classes = computed(() => {
  return twMerge(
    ['mask mask-squircle relative h-10 w-10 bg-gray-750'],
    props.class,
  )
})
const url = computed(() => {
  return gravatarUrl(props.email)
})
</script>

<template>
  <div class="relative p-px">
    <div
      class="picture-border mask mask-squircle absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 scale-[103%]"
    ></div>
    <div :class="classes">
      <div
        aria-hidden="true"
        className="absolute inset-0 flex justify-center items-center z-[-1] font-medium initials "
      >
        {{ getInitials(name || email) }}
      </div>
      <img :src="url" :alt="name || email" class="z-10" />
    </div>
  </div>
</template>
