<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

function onClickMenu() {
  useAnalytics({
    name: 'Navigation: Open Notification Dropdown Menu',
    showDivision: true,
  })
}
</script>

<template>
  <Menu ref="popover" as="div" class="relative">
    <MenuButton
      class="group inline-flex rounded-md focus:outline-none"
      @click="onClickMenu"
    >
      <span
        class="flex h-10 w-10 items-center justify-center rounded-lg text-center group-hover:bg-primary group-hover:text-black"
      >
        <NuxtIcon
          name="notification"
          filled
          class="text-2xl text-white group-hover:text-black"
        />
      </span>
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 mt-2 flex w-72 max-w-[calc(100vw-50px)] origin-top-right flex-col gap-1 rounded-md bg-gray-900 p-2 shadow-lg"
      >
        <h2>Notifications</h2>
        <hr class="my-2 h-px border-t-0 bg-gray-850" />
      </MenuItems>
    </transition>
  </Menu>
</template>

<style lang="postcss" scoped>
:deep(.router-link-active) {
  @apply bg-gray-850 text-primary;
}
</style>
