import revive_payload_client_5kc6TMUaSx from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qwsbgaa3mX from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wM5BAC2S0V from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_YchUTUgrD6 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.4.2_@vue+compiler-core@3.5.6_fuse.js@7.0.0_nuxt@3.13._pp754bna5fol4hgk36xlraejtu/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_9PflcH7cWD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7Ae9xAhM5k from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XbHz887hv7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NQ1PjM0Cp5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WDs8cMsseg from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.3_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_b4QaSTGdJu from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import plugin_3mDLlUpvJA from "/opt/build/repo/node_modules/.pnpm/nuxt-highcharts@3.1.1/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_client_bYFa95F1nV from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.21.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_m9whD8VCPY from "/opt/build/repo/node_modules/.pnpm/nuxt-supported-browsers@0.0.5_rollup@4.21.3/node_modules/nuxt-supported-browsers/dist/runtime/plugin.mjs";
import plugin_mnKCOEzE8U from "/opt/build/repo/node_modules/.pnpm/mixpanel-nuxt@0.0.7_rollup@4.21.3/node_modules/mixpanel-nuxt/dist/runtime/plugin.mjs";
import plugin_BncEGiwCHa from "/opt/build/repo/modules/dayjs/runtime/plugin.ts";
import i18n_PbjOXdO1et from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.4.2_@vue+compiler-core@3.5.6_fuse.js@7.0.0_nuxt@3.13._pp754bna5fol4hgk36xlraejtu/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import plugin_SJrXbtAYjk from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.2.3_@types+node@20.16.5_@vue+test-utils@2.4.6_h3@1.12.0_nitropack@2.9.7_nuxi_jiemu23m6ug6ziz6fvxawauynu/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import i18n_OmpYR2TwfS from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n-edge@8.3.1-28617338.6223696_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import formkit_autoanimate_jY1mIxaBJF from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.5_@vueuse+integrations@11.1.0_esbuild@_t2fjit223wghebppcifg2yprcy/node_modules/@flnt-accurait/web-components/plugins/formkit-autoanimate.ts";
import tailwind_merge_on5jRjIv1n from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.5_@vueuse+integrations@11.1.0_esbuild@_t2fjit223wghebppcifg2yprcy/node_modules/@flnt-accurait/web-components/plugins/tailwind-merge.ts";
import vfm_X7mSZpZqAo from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.5_@vueuse+integrations@11.1.0_esbuild@_t2fjit223wghebppcifg2yprcy/node_modules/@flnt-accurait/web-components/plugins/vfm.ts";
import _01_newrelic_browser_client_golEfeKy9C from "/opt/build/repo/plugins/01.newrelic-browser.client.ts";
import _01_sentry_client_2rh1pDAIty from "/opt/build/repo/plugins/01.sentry.client.ts";
import _02_casl_dK6AW1kOxW from "/opt/build/repo/plugins/02.casl.ts";
import _03_auth_0qOenMw9ot from "/opt/build/repo/plugins/03.auth.ts";
import _04_vue_query_pfhLWgmfpy from "/opt/build/repo/plugins/04.vue-query.ts";
import _05_companies_lZ1i0cuh0R from "/opt/build/repo/plugins/05.companies.ts";
import _06_toastify_YXraJs4osh from "/opt/build/repo/plugins/06.toastify.ts";
import _07_vfm_2uugo2TItU from "/opt/build/repo/plugins/07.vfm.ts";
import _08_google_maps_4T7wxetCaw from "/opt/build/repo/plugins/08.google-maps.ts";
export default [
  revive_payload_client_5kc6TMUaSx,
  unhead_qwsbgaa3mX,
  router_wM5BAC2S0V,
  _0_siteConfig_YchUTUgrD6,
  payload_client_9PflcH7cWD,
  navigation_repaint_client_7Ae9xAhM5k,
  check_outdated_build_client_XbHz887hv7,
  chunk_reload_client_NQ1PjM0Cp5,
  plugin_vue3_WDs8cMsseg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_b4QaSTGdJu,
  formkitPlugin_pZqjah0RUG,
  floating_vue_EIcJ7xiw0h,
  plugin_3mDLlUpvJA,
  plugin_client_bYFa95F1nV,
  plugin_m9whD8VCPY,
  plugin_mnKCOEzE8U,
  plugin_BncEGiwCHa,
  i18n_PbjOXdO1et,
  plugin_SJrXbtAYjk,
  i18n_OmpYR2TwfS,
  formkit_autoanimate_jY1mIxaBJF,
  tailwind_merge_on5jRjIv1n,
  vfm_X7mSZpZqAo,
  _01_newrelic_browser_client_golEfeKy9C,
  _01_sentry_client_2rh1pDAIty,
  _02_casl_dK6AW1kOxW,
  _03_auth_0qOenMw9ot,
  _04_vue_query_pfhLWgmfpy,
  _05_companies_lZ1i0cuh0R,
  _06_toastify_YXraJs4osh,
  _07_vfm_2uugo2TItU,
  _08_google_maps_4T7wxetCaw
]