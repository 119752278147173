<script setup>
import { ModalsContainer } from 'vue-final-modal'

const anError = useError()
const handleError = () => clearError({ redirect: '/' })
const isDev = useRuntimeConfig().public.ENV === 'local'
</script>

<template>
  <div class="flex w-full">
    <ClientOnly>
      <Navigation class="fixed left-0 right-0 z-10" />
    </ClientOnly>

    <div class="container mx-auto mt-32 text-center">
      <h1 v-if="anError && anError.statusCode" class="text-4xl">
        {{ anError.statusCode }}
      </h1>

      <div v-if="anError && anError.statusCode === 404" class="">
        <NuxtIcon
          name="empty-folder"
          class="mx-auto my-8 flex justify-center text-8xl text-gray-200"
        />
        <h2>Page not found</h2>
      </div>

      <div v-else-if="anError && anError.statusCode === 403">
        <h2>{{ anError.message }}</h2>
        <p>You don't have permissions to access this page</p>
      </div>

      <Button @click="handleError"> Back to home </Button>

      <div v-if="isDev" class="flex justify-center">
        <h2 v-if="anError" class="mt-4">{{ anError.message }}</h2>
      </div>
    </div>

    <ModalsContainer />
  </div>
</template>
