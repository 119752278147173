import Uppy, {
  ErrorResponse,
  SuccessResponse,
  UploadResult,
  UppyFile,
} from '@uppy/core'
import { ICompanies } from '~/composables/useAPI/types'

export enum IQueueType {
  uploading = 'uploading',
  finished = 'finished',
  error = 'error',
}

export type IFileUploadObject = {
  file: UppyFile<Record<string, unknown>, Record<string, unknown>>
  progress: number
  error?: Error
  response?: ErrorResponse | SuccessResponse
  result?: UploadResult<Record<string, unknown>, Record<string, unknown>>
}

export type FileFullObject = {
  uppy: Uppy
  uploading: IFileUploadObject[]
  finished: IFileUploadObject[]
  error: IFileUploadObject[]
  company: ICompanies
}
export type IFileUpload = Record<string, FileFullObject>

export type IFilesData = {
  files: IFileUploadObject[]
  company: ICompanies
}

export type IFilesResult = {
  [K in IQueueType]: IFilesData[]
}
