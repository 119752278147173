import type { RouterConfig } from '@nuxt/schema'
import qs from 'qs'
import { isNullish } from '@morev/utils'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  stringifyQuery: (query) => {
    return qs.stringify(query, {
      encode: true,
      encodeValuesOnly: true,
      format: 'RFC1738',
      arrayFormat: 'brackets',
      filter: (_, value) => {
        if (isNullish(value) || value === '') return
        if (Array.isArray(value) && value.length === 0) return
        return value
      },
    })
  },
  parseQuery: (query) => {
    return qs.parse(query, {
      decoder(str, decoder, charset) {
        const strWithoutPlus = str.replace(/\+/g, ' ')

        if (charset === 'iso-8859-1') {
          return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, (match) =>
            String.fromCharCode(parseInt(match.substring(1), 16)),
          )
        }

        if (/^[+-]?\d+(\.\d+)?$/.test(str)) {
          return parseFloat(str)
        }

        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined,
        }

        if (str in keywords) {
          return keywords[str as keyof typeof keywords]
        }

        // utf-8
        try {
          return decodeURIComponent(strWithoutPlus)
        } catch (e) {
          return strWithoutPlus
        }
      },
    })
  },
}
