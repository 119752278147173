import {
  type INode,
  type INodeGroup,
  type INodeObject,
  NodeKind,
} from '@manager'

export const generateIdPathMap = (node: INodeGroup | undefined) => {
  const map = new Map<number, string>()

  if (node) {
    traverseNodes(map, node)
  }

  return map
}

function traverseNodes(
  map: Map<number, string>,
  node: INode,
  currentPath = '',
) {
  if (hasNodes(node)) {
    node.nodes.forEach((childNode) => {
      const childPath = constructPath(currentPath, childNode)

      if (childNode.id) {
        // If the child node has an id, add it to the map
        map.set(childNode.id, childPath)
      } else if (node.nodes.length === 1) {
        // If the parent node has only one child, it means that we can map
        // the parent node id to the child path
        // The child path will point to the child name instead of the id
        map.set(node.id, childPath)
      } else if (currentPath !== '') {
        // If the parent node has more than one child, it means that it is
        // a group of fields, so we map the parent node id to the current path
        map.set(node.id, currentPath)
      }

      // TODO: Refactor
      if (isNodeObject(childNode)) {
        // If is an object, add the [] or [0] to the path
        traverseNodes(
          map,
          childNode,
          childPath + (childNode.canAddCopies ? '[]' : '[0]'),
        )
      } else {
        traverseNodes(map, childNode, childPath)
      }
    })
  }
}

// Function to construct the path for a node
function constructPath(path: string, childNode: INode) {
  let newPath = joinPath(path, childNode.name)

  if (isNodeObject(childNode)) {
    // If is an object, add the data
    newPath = joinPath(newPath, 'data')
  } else if (isNodeGroup(childNode)) {
    // If is a group, we don't add it to the path
    newPath = path
  }

  return newPath
}

// Helper function to join paths
export function joinPath(
  currentPath: string,
  path: string | number | undefined,
) {
  return path
    ? currentPath
      ? `${currentPath}.${path}`
      : `${path}`
    : currentPath
}

// Helper function to check if a node has child nodes
function hasNodes(node: INode): node is INodeGroup {
  return 'nodes' in node && node.nodes.length > 0
}

// Helper function to check if a node is kind OBJECT
function isNodeObject(node: INode): node is INodeObject {
  return node.kind === NodeKind.OBJECT
}

// Helper function to check if a node is kind GROUP
function isNodeGroup(node: INode): node is INodeGroup {
  return node.kind === NodeKind.GROUP
}
