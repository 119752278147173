import type { RentReviewTableValue } from '../types'
import { IncreaseTableMethod } from '@register/constants/review/IncreaseTableMethod'

export const hasGreaterOf = (rentIncrease: RentReviewTableValue) =>
  !!rentIncrease.greaterOf

export const hasLesserOf = (rentIncrease: RentReviewTableValue) =>
  !!rentIncrease.lesserOf

export const formatFixedPercentValue = (rentIncrease: RentReviewTableValue) => {
  if (rentIncrease.fixedPercent)
    return rentIncrease.fixedPercent?.replaceAll('%', '') + '%'
  return `-`
}

function enforcePercentage(value?: string) {
  if (!value) return
  return value.replaceAll('%', '') + '%'
}

export const formatFixedAmountValue = (rentIncrease: RentReviewTableValue) => {
  const pattern = /^[0-9,.]+$/

  if (rentIncrease.fixedAmount && pattern.test(rentIncrease.fixedAmount)) {
    const { currentDivision } = useDivisions()
    //TODO: fix localization type
    const localization = currentDivision.value.localization

    const locale = localization.locale ?? 'en-AU'
    const currency = localization.currency?.id || 'AUD'
    const currencyDisplay = 'narrowSymbol'

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay,
    }).format(toFloat(rentIncrease.fixedAmount, 0))
  }
  return rentIncrease.fixedAmount ?? '-'
}

export const formatCPIPlusValue = (rentIncrease: RentReviewTableValue) => {
  let value: string = 'CPI +'

  if (rentIncrease.cpiPercent) {
    value += ` ${enforcePercentage(rentIncrease.cpiPercent)}`
  }

  if (rentIncrease.cpiCollar) {
    value += ` Collar ${enforcePercentage(rentIncrease.cpiCollar)}`
  }

  if (rentIncrease.cpiCap) {
    value += ` Cap ${enforcePercentage(rentIncrease.cpiCap)}`
  }

  return value
}

export const formatCPIValue = (rentIncrease: RentReviewTableValue) => {
  let value: string = 'CPI'

  if (rentIncrease.cpiCollar) {
    value += ` Collar ${enforcePercentage(rentIncrease.cpiCollar)}`
  }

  if (rentIncrease.cpiCap) {
    value += ` Cap ${enforcePercentage(rentIncrease.cpiCap)}`
  }

  return value
}

export const formatMarketValue = (rentIncrease: RentReviewTableValue) => {
  let value: string = 'Market review'

  // if (rentIncrease.marketReviewDate) {
  //   value += ` ${rentIncrease.marketReviewDate}`
  // }

  if (rentIncrease.marketReviewCollar) {
    value += ` Collar ${enforcePercentage(rentIncrease.marketReviewCollar)}`
  }

  if (rentIncrease.marketReviewCap) {
    value += ` Cap ${enforcePercentage(rentIncrease.marketReviewCap)}`
  }

  return value
}

export const formatFormulaValue = (rentIncrease: RentReviewTableValue) => {
  return rentIncrease.formula ?? 'Formula N/A'
}

export const formatRentIncreaseValue = (rentIncrease: RentReviewTableValue) => {
  let value: string = ''

  switch (rentIncrease.method) {
    case IncreaseTableMethod.FIXED_PERCENT:
      value = formatFixedPercentValue(rentIncrease)
      break
    case IncreaseTableMethod.FIXED_AMOUNT:
      value = formatFixedAmountValue(rentIncrease)
      break
    case IncreaseTableMethod.CPI_PLUS:
      value = formatCPIPlusValue(rentIncrease)
      break
    case IncreaseTableMethod.CPI:
      value = formatCPIValue(rentIncrease)
      break
    case IncreaseTableMethod.MARKET:
      value = formatMarketValue(rentIncrease)
      break
    case IncreaseTableMethod.FORMULA:
      value = formatFormulaValue(rentIncrease)
      break
  }

  if (hasGreaterOf(rentIncrease)) {
    value = ` Greater of ${value} or ${enforcePercentage(rentIncrease.greaterOf)}`
  }

  if (hasLesserOf(rentIncrease)) {
    value = ` Lesser of ${value} or ${enforcePercentage(rentIncrease.lesserOf)}`
  }

  return value || '-'
}
