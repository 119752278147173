import { defineAbilityFor, updateAbilityFor } from '~/config/ability'
import type { AuthUser } from '~/composables/auth/types'

export interface AbilityPluginOptions {
  useGlobalProperties?: boolean
}

export default defineNuxtPlugin(() => {
  // Init acl
  const ability = defineAbilityFor(null)

  const updateAbility = (user?: AuthUser) => {
    ability.update(updateAbilityFor(user))
  }

  return {
    provide: {
      updateAbility,
      ability,
      can: ability.can.bind(ability),
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $updateAbility(user?: AuthUser): void
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $updateAbility(user?: AuthUser): void
  }
}
