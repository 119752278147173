import { version } from '../package.json'

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

export default defineNuxtPlugin((nuxtApp) => {
  // if (nuxtApp.$config.public.ENV === 'local') {
  //   return
  // }

  const config = useRuntimeConfig().public.newrelic
  const options = {
    init: {
      distributed_tracing: {
        enabled: true,
      },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net', 'localhost'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: config.licenseKey,
      applicationID: config.applicationId,
      sa: 1,
    },
    loader_config: {
      accountID: config.accountId,
      trustKey: config.accountId,
      agentID: config.applicationId,
      licenseKey: config.licenseKey,
      applicationID: config.applicationId,
    },
  }
  // The agent loader code executes immediately on instantiation.
  const newRelic = new BrowserAgent(options)

  newRelic.setApplicationVersion(version)

  return {
    provide: {
      newRelic,
    },
  }
})
