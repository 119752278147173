import type { AuthUser } from './types'
import { AuthStatus } from './constants'

export const useAuthState = () => {
  const data = useState<AuthUser>('session:data', () => undefined)

  const hasInitialSession = data.value !== undefined

  // If session exists, initialize as already synced
  const lastRefreshedAt = useState<Date | undefined>(
    'session:lastRefreshedAt',
    () => {
      if (hasInitialSession) {
        return new Date()
      }

      return undefined
    },
  )

  // If session exists, initialize as not loading
  const loading = useState<boolean>('session:loading', () => !hasInitialSession)

  const status = computed<AuthStatus>(() => {
    if (data.value) {
      return AuthStatus.Authenticated
    }

    if (loading.value) {
      return AuthStatus.Loading
    }

    return AuthStatus.Unauthenticated
  })

  return {
    data,
    loading,
    lastRefreshedAt,
    status,
  }
}
