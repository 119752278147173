import { useQuery } from '@tanstack/vue-query'
import { Product } from '~/composables/useAPI/types'
import type { IReminderCountResponse } from '@critical-dates'

const fetchCount = async (divisionId: string, date: string) => {
  return fetchAuthorized<IReminderCountResponse>(
    `/companies/${divisionId}/reminders/count`,
    {
      query: {
        date,
      },
    },
  )
}

export const useApiRemindersCount = (date?: string) => {
  const divisionId = getCurrentDivisionId(false)
  const { $can } = useNuxtApp()

  if (!date) {
    const dayjs = useDayjs()
    date = dayjs().format('YYYY-MM-DD').toString()
  }

  const query = useQuery({
    queryKey: ['companies', divisionId, 'reminders', 'count', date],
    queryFn: () => fetchCount(divisionId!, date),
    enabled: computed(
      () => !!divisionId && $can('product', Product.CRITICAL_DATES),
    ),
  })

  return query
}
