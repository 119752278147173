import { default as _404B00KTGNlR5Meta } from "/opt/build/repo/pages/404.vue?macro=true";
import { default as create_45profileWvrSOkAjTwMeta } from "/opt/build/repo/layers/authentication/pages/account/create-profile.vue?macro=true";
import { default as invitationVBFaoBLMrfMeta } from "/opt/build/repo/layers/authentication/pages/account/invitation.vue?macro=true";
import { default as signup_45completed9Dfx2qRcWwMeta } from "/opt/build/repo/layers/authentication/pages/account/signup-completed.vue?macro=true";
import { default as force_45logoutEpcTmmNcJHMeta } from "/opt/build/repo/layers/authentication/pages/auth/force-logout.vue?macro=true";
import { default as Register6DbQxaxR5mMeta } from "/opt/build/repo/layers/authentication/pages/auth/Register.vue?macro=true";
import { default as callbackaUD77BP2RdMeta } from "/opt/build/repo/layers/authentication/pages/callback.vue?macro=true";
import { default as indexF2SYOEEjbZMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/index.vue?macro=true";
import { default as _91triggerId_93S9g5HCzEVeMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/[triggerId].vue?macro=true";
import { default as indexfGXJjotFhtMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/index.vue?macro=true";
import { default as critical_45datesY0mNSfwOFiMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates.vue?macro=true";
import { default as indextNgz7dmy7KMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/index.vue?macro=true";
import { default as _91amendmentId_93R4XvhtCgInMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/[amendmentId].vue?macro=true";
import { default as indexzuqu2xMBg1Meta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/index.vue?macro=true";
import { default as amendmentsrnSP2e36MVMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments.vue?macro=true";
import { default as documentsDqJZ4zaocRMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/documents.vue?macro=true";
import { default as headleasegwdw2UNbQrMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/headlease.vue?macro=true";
import { default as indexrbCXjQ66tvMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/index.vue?macro=true";
import { default as _91id_93tdsr75c85iMeta } from "/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/[referenceName]/[id].vue?macro=true";
import { default as indexXr3qzYLPWJMeta } from "/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/index.vue?macro=true";
import { default as managerv2Uk1GUiA8Meta } from "/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager.vue?macro=true";
import { default as remindersNQYJQYoHBnMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/reminders.vue?macro=true";
import { default as settingszNx1CSUX3tMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/settings.vue?macro=true";
import { default as subleasesEQvgJydHNcMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/subleases.vue?macro=true";
import { default as summaryvbKZqFqMuZMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/summary.vue?macro=true";
import { default as _91leaseDocumentId_93nJK4GZtorBMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId].vue?macro=true";
import { default as _91linkDocumentId_93onuwYPKccYMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/link-document/[linkDocumentId].vue?macro=true";
import { default as indexXHB5gWNWuTMeta } from "/opt/build/repo/layers/portfolio/pages/division/[companyId]/portfolio/index.vue?macro=true";
import { default as useReportsQueryQGn3TJYKCcMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/composables/useReportsQuery.ts?macro=true";
import { default as indexywA7zkZzUGMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/index.vue?macro=true";
import { default as viewmuSnZRJ8aIMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view.vue?macro=true";
import { default as indexIGleHNgWcsMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/index.vue?macro=true";
import { default as _91section_93Doj9dVLNcsMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/[section].vue?macro=true";
import { default as _91groupId_93RKFJ438jbjMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/[groupId].vue?macro=true";
import { default as indexl3qofAqG8NMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/index.vue?macro=true";
import { default as confidence_45scoreishrZLvIM2Meta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score.vue?macro=true";
import { default as indexRB1evl5KHvMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/upload-reference/index.vue?macro=true";
import { default as usersiaTZGnEm8pMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/users.vue?macro=true";
import { default as settingshBi8W5BZnVMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings.vue?macro=true";
import { default as failedHAqODlSYYvMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/failed.vue?macro=true";
import { default as indexVaZFMBAlYcMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/index.vue?macro=true";
import { default as processingtXTVMysEtmMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/processing.vue?macro=true";
import { default as workflowEHqIeI5TfTMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow.vue?macro=true";
import { default as indexabOJ19U5J5Meta } from "/opt/build/repo/layers/dashboard/pages/index.vue?macro=true";
import { default as _91groupId_93vhJLXshqRTMeta } from "/opt/build/repo/layers/settings/pages/settings/confidence-score/[groupId].vue?macro=true";
import { default as indexctgbcMT4FDMeta } from "/opt/build/repo/layers/settings/pages/settings/confidence-score/index.vue?macro=true";
import { default as confidence_45scoreFdkauB6XjlMeta } from "/opt/build/repo/layers/settings/pages/settings/confidence-score.vue?macro=true";
import { default as createlGCGcpviS0Meta } from "/opt/build/repo/layers/settings/pages/settings/divisions/create.vue?macro=true";
import { default as index0ahCeIvzezMeta } from "/opt/build/repo/layers/settings/pages/settings/divisions/index.vue?macro=true";
import { default as indexT1PSPxfkbnMeta } from "/opt/build/repo/layers/settings/pages/settings/profile/index.vue?macro=true";
import { default as index9pVNJwoO9tMeta } from "/opt/build/repo/layers/settings/pages/settings/upload-reference/index.vue?macro=true";
import { default as indexmQbwge5nNcMeta } from "/opt/build/repo/layers/settings/pages/settings/users/[userId]/index.vue?macro=true";
import { default as permissions0zU1Dj9lCPMeta } from "/opt/build/repo/layers/settings/pages/settings/users/[userId]/permissions.vue?macro=true";
import { default as indexbWF4FOcH2RMeta } from "/opt/build/repo/layers/settings/pages/settings/users/index.vue?macro=true";
import { default as invite_45userqf0BovFMjmMeta } from "/opt/build/repo/layers/settings/pages/settings/users/invite-user.vue?macro=true";
import { default as settingsPm2MH03QgaMeta } from "/opt/build/repo/layers/settings/pages/settings.vue?macro=true";
import { default as unsupported_45browserHJmWimS945Meta } from "/opt/build/repo/pages/unsupported-browser.vue?macro=true";
export default [
  {
    name: "404___en",
    path: "/404",
    component: () => import("/opt/build/repo/pages/404.vue")
  },
  {
    name: "404___pt-BR",
    path: "/pt-BR/404",
    component: () => import("/opt/build/repo/pages/404.vue")
  },
  {
    name: "account-create-profile___en",
    path: "/account/create-profile",
    component: () => import("/opt/build/repo/layers/authentication/pages/account/create-profile.vue")
  },
  {
    name: "account-create-profile___pt-BR",
    path: "/pt-BR/account/create-profile",
    component: () => import("/opt/build/repo/layers/authentication/pages/account/create-profile.vue")
  },
  {
    name: "account-invitation___en",
    path: "/account/invitation",
    meta: invitationVBFaoBLMrfMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/account/invitation.vue")
  },
  {
    name: "account-invitation___pt-BR",
    path: "/pt-BR/account/invitation",
    meta: invitationVBFaoBLMrfMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/account/invitation.vue")
  },
  {
    name: "account-signup-completed___en",
    path: "/account/signup-completed",
    meta: signup_45completed9Dfx2qRcWwMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/account/signup-completed.vue")
  },
  {
    name: "account-signup-completed___pt-BR",
    path: "/pt-BR/account/signup-completed",
    meta: signup_45completed9Dfx2qRcWwMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/account/signup-completed.vue")
  },
  {
    name: "auth-force-logout___en",
    path: "/auth/force-logout",
    component: () => import("/opt/build/repo/layers/authentication/pages/auth/force-logout.vue")
  },
  {
    name: "auth-force-logout___pt-BR",
    path: "/pt-BR/auth/force-logout",
    component: () => import("/opt/build/repo/layers/authentication/pages/auth/force-logout.vue")
  },
  {
    name: "auth-Register___en",
    path: "/auth/Register",
    meta: Register6DbQxaxR5mMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/auth/Register.vue")
  },
  {
    name: "auth-Register___pt-BR",
    path: "/pt-BR/auth/Register",
    meta: Register6DbQxaxR5mMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/auth/Register.vue")
  },
  {
    name: "callback___en",
    path: "/callback",
    meta: callbackaUD77BP2RdMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/callback.vue")
  },
  {
    name: "callback___pt-BR",
    path: "/pt-BR/callback",
    meta: callbackaUD77BP2RdMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/callback.vue")
  },
  {
    name: critical_45datesY0mNSfwOFiMeta?.name,
    path: "/division/:companyId()/critical-dates",
    meta: critical_45datesY0mNSfwOFiMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates.vue"),
    children: [
  {
    name: "division-companyId-critical-dates___en",
    path: "",
    meta: indexF2SYOEEjbZMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/index.vue")
  },
  {
    name: "division-companyId-critical-dates-triggers-triggerId___en",
    path: "triggers/:triggerId()",
    meta: _91triggerId_93S9g5HCzEVeMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/[triggerId].vue")
  },
  {
    name: "division-companyId-critical-dates-triggers___en",
    path: "triggers",
    meta: indexfGXJjotFhtMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/index.vue")
  }
]
  },
  {
    name: critical_45datesY0mNSfwOFiMeta?.name,
    path: "/pt-BR/division/:companyId()/critical-dates",
    meta: critical_45datesY0mNSfwOFiMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates.vue"),
    children: [
  {
    name: "division-companyId-critical-dates___pt-BR",
    path: "",
    meta: indexF2SYOEEjbZMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/index.vue")
  },
  {
    name: "division-companyId-critical-dates-triggers-triggerId___pt-BR",
    path: "triggers/:triggerId()",
    meta: _91triggerId_93S9g5HCzEVeMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/[triggerId].vue")
  },
  {
    name: "division-companyId-critical-dates-triggers___pt-BR",
    path: "triggers",
    meta: indexfGXJjotFhtMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/index.vue")
  }
]
  },
  {
    name: "division-companyId___en",
    path: "/division/:companyId()",
    meta: indextNgz7dmy7KMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/index.vue")
  },
  {
    name: "division-companyId___pt-BR",
    path: "/pt-BR/division/:companyId()",
    meta: indextNgz7dmy7KMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/index.vue")
  },
  {
    name: _91leaseDocumentId_93nJK4GZtorBMeta?.name,
    path: "/division/:companyId()/lease/:leaseDocumentId()",
    meta: _91leaseDocumentId_93nJK4GZtorBMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId].vue"),
    children: [
  {
    name: amendmentsrnSP2e36MVMeta?.name,
    path: "amendments",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments.vue"),
    children: [
  {
    name: "division-companyId-lease-leaseDocumentId-amendments-amendmentId___en",
    path: ":amendmentId()",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/[amendmentId].vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-amendments___en",
    path: "",
    meta: indexzuqu2xMBg1Meta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/index.vue")
  }
]
  },
  {
    name: "division-companyId-lease-leaseDocumentId-documents___en",
    path: "documents",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/documents.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-headlease___en",
    path: "headlease",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/headlease.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId___en",
    path: "",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/index.vue")
  },
  {
    name: managerv2Uk1GUiA8Meta?.name,
    path: "manager",
    meta: managerv2Uk1GUiA8Meta || {},
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager.vue"),
    children: [
  {
    name: "division-companyId-lease-leaseDocumentId-manager-referenceName-id___en",
    path: ":referenceName()/:id()",
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/[referenceName]/[id].vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-manager___en",
    path: "",
    meta: indexXr3qzYLPWJMeta || {},
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/index.vue")
  }
]
  },
  {
    name: "division-companyId-lease-leaseDocumentId-reminders___en",
    path: "reminders",
    meta: remindersNQYJQYoHBnMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/reminders.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-settings___en",
    path: "settings",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/settings.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-subleases___en",
    path: "subleases",
    meta: subleasesEQvgJydHNcMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/subleases.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-summary___en",
    path: "summary",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/summary.vue")
  }
]
  },
  {
    name: _91leaseDocumentId_93nJK4GZtorBMeta?.name,
    path: "/pt-BR/division/:companyId()/lease/:leaseDocumentId()",
    meta: _91leaseDocumentId_93nJK4GZtorBMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId].vue"),
    children: [
  {
    name: amendmentsrnSP2e36MVMeta?.name,
    path: "amendments",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments.vue"),
    children: [
  {
    name: "division-companyId-lease-leaseDocumentId-amendments-amendmentId___pt-BR",
    path: ":amendmentId()",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/[amendmentId].vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-amendments___pt-BR",
    path: "",
    meta: indexzuqu2xMBg1Meta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/index.vue")
  }
]
  },
  {
    name: "division-companyId-lease-leaseDocumentId-documents___pt-BR",
    path: "documents",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/documents.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-headlease___pt-BR",
    path: "headlease",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/headlease.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId___pt-BR",
    path: "",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/index.vue")
  },
  {
    name: managerv2Uk1GUiA8Meta?.name,
    path: "manager",
    meta: managerv2Uk1GUiA8Meta || {},
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager.vue"),
    children: [
  {
    name: "division-companyId-lease-leaseDocumentId-manager-referenceName-id___pt-BR",
    path: ":referenceName()/:id()",
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/[referenceName]/[id].vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-manager___pt-BR",
    path: "",
    meta: indexXr3qzYLPWJMeta || {},
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/index.vue")
  }
]
  },
  {
    name: "division-companyId-lease-leaseDocumentId-reminders___pt-BR",
    path: "reminders",
    meta: remindersNQYJQYoHBnMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/reminders.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-settings___pt-BR",
    path: "settings",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/settings.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-subleases___pt-BR",
    path: "subleases",
    meta: subleasesEQvgJydHNcMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/subleases.vue")
  },
  {
    name: "division-companyId-lease-leaseDocumentId-summary___pt-BR",
    path: "summary",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/summary.vue")
  }
]
  },
  {
    name: "division-companyId-link-document-linkDocumentId___en",
    path: "/division/:companyId()/link-document/:linkDocumentId()",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/link-document/[linkDocumentId].vue")
  },
  {
    name: "division-companyId-link-document-linkDocumentId___pt-BR",
    path: "/pt-BR/division/:companyId()/link-document/:linkDocumentId()",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/link-document/[linkDocumentId].vue")
  },
  {
    name: "division-companyId-portfolio___en",
    path: "/division/:companyId()/portfolio",
    meta: indexXHB5gWNWuTMeta || {},
    component: () => import("/opt/build/repo/layers/portfolio/pages/division/[companyId]/portfolio/index.vue")
  },
  {
    name: "division-companyId-portfolio___pt-BR",
    path: "/pt-BR/division/:companyId()/portfolio",
    meta: indexXHB5gWNWuTMeta || {},
    component: () => import("/opt/build/repo/layers/portfolio/pages/division/[companyId]/portfolio/index.vue")
  },
  {
    name: "division-companyId-reports-composables-useReportsQuery___en",
    path: "/division/:companyId()/reports/composables/useReportsQuery",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/composables/useReportsQuery.ts")
  },
  {
    name: "division-companyId-reports-composables-useReportsQuery___pt-BR",
    path: "/pt-BR/division/:companyId()/reports/composables/useReportsQuery",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/composables/useReportsQuery.ts")
  },
  {
    name: "division-companyId-reports___en",
    path: "/division/:companyId()/reports",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/index.vue")
  },
  {
    name: "division-companyId-reports___pt-BR",
    path: "/pt-BR/division/:companyId()/reports",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/index.vue")
  },
  {
    name: "division-companyId-reports-view___en",
    path: "/division/:companyId()/reports/view",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view.vue")
  },
  {
    name: "division-companyId-reports-view___pt-BR",
    path: "/pt-BR/division/:companyId()/reports/view",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view.vue")
  },
  {
    name: settingshBi8W5BZnVMeta?.name,
    path: "/division/:companyId()/settings",
    meta: settingshBi8W5BZnVMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings.vue"),
    children: [
  {
    name: "division-companyId-settings___en",
    path: "",
    meta: indexIGleHNgWcsMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/index.vue")
  },
  {
    name: "division-companyId-settings-modules-section___en",
    path: "modules/:section()",
    meta: _91section_93Doj9dVLNcsMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/[section].vue")
  },
  {
    name: confidence_45scoreishrZLvIM2Meta?.name,
    path: "modules/confidence-score",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score.vue"),
    children: [
  {
    name: "division-companyId-settings-modules-confidence-score-groupId___en",
    path: ":groupId()",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/[groupId].vue")
  },
  {
    name: "division-companyId-settings-modules-confidence-score___en",
    path: "",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/index.vue")
  }
]
  },
  {
    name: "division-companyId-settings-modules-upload-reference___en",
    path: "modules/upload-reference",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/upload-reference/index.vue")
  },
  {
    name: "division-companyId-settings-users___en",
    path: "users",
    meta: usersiaTZGnEm8pMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/users.vue")
  }
]
  },
  {
    name: settingshBi8W5BZnVMeta?.name,
    path: "/pt-BR/division/:companyId()/settings",
    meta: settingshBi8W5BZnVMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings.vue"),
    children: [
  {
    name: "division-companyId-settings___pt-BR",
    path: "",
    meta: indexIGleHNgWcsMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/index.vue")
  },
  {
    name: "division-companyId-settings-modules-section___pt-BR",
    path: "modules/:section()",
    meta: _91section_93Doj9dVLNcsMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/[section].vue")
  },
  {
    name: confidence_45scoreishrZLvIM2Meta?.name,
    path: "modules/confidence-score",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score.vue"),
    children: [
  {
    name: "division-companyId-settings-modules-confidence-score-groupId___pt-BR",
    path: ":groupId()",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/[groupId].vue")
  },
  {
    name: "division-companyId-settings-modules-confidence-score___pt-BR",
    path: "",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/index.vue")
  }
]
  },
  {
    name: "division-companyId-settings-modules-upload-reference___pt-BR",
    path: "modules/upload-reference",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/upload-reference/index.vue")
  },
  {
    name: "division-companyId-settings-users___pt-BR",
    path: "users",
    meta: usersiaTZGnEm8pMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/users.vue")
  }
]
  },
  {
    name: workflowEHqIeI5TfTMeta?.name,
    path: "/division/:companyId()/workflow",
    meta: workflowEHqIeI5TfTMeta || {},
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow.vue"),
    children: [
  {
    name: "division-companyId-workflow-failed___en",
    path: "failed",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/failed.vue")
  },
  {
    name: "division-companyId-workflow___en",
    path: "",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/index.vue")
  },
  {
    name: "division-companyId-workflow-processing___en",
    path: "processing",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/processing.vue")
  }
]
  },
  {
    name: workflowEHqIeI5TfTMeta?.name,
    path: "/pt-BR/division/:companyId()/workflow",
    meta: workflowEHqIeI5TfTMeta || {},
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow.vue"),
    children: [
  {
    name: "division-companyId-workflow-failed___pt-BR",
    path: "failed",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/failed.vue")
  },
  {
    name: "division-companyId-workflow___pt-BR",
    path: "",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/index.vue")
  },
  {
    name: "division-companyId-workflow-processing___pt-BR",
    path: "processing",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/processing.vue")
  }
]
  },
  {
    name: "index___en",
    path: "/",
    meta: indexabOJ19U5J5Meta || {},
    component: () => import("/opt/build/repo/layers/dashboard/pages/index.vue")
  },
  {
    name: "index___pt-BR",
    path: "/pt-BR",
    meta: indexabOJ19U5J5Meta || {},
    component: () => import("/opt/build/repo/layers/dashboard/pages/index.vue")
  },
  {
    name: "settings___en",
    path: "/settings",
    component: () => import("/opt/build/repo/layers/settings/pages/settings.vue"),
    children: [
  {
    name: confidence_45scoreFdkauB6XjlMeta?.name,
    path: "confidence-score",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score.vue"),
    children: [
  {
    name: "settings-confidence-score-groupId___en",
    path: ":groupId()",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score/[groupId].vue")
  },
  {
    name: "settings-confidence-score___en",
    path: "",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score/index.vue")
  }
]
  },
  {
    name: "settings-divisions-create___en",
    path: "divisions/create",
    meta: createlGCGcpviS0Meta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/divisions/create.vue")
  },
  {
    name: "settings-divisions___en",
    path: "divisions",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/divisions/index.vue")
  },
  {
    name: "settings-profile___en",
    path: "profile",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/profile/index.vue")
  },
  {
    name: "settings-upload-reference___en",
    path: "upload-reference",
    meta: index9pVNJwoO9tMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/upload-reference/index.vue")
  },
  {
    name: "settings-users-userId___en",
    path: "users/:userId()",
    meta: indexmQbwge5nNcMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/[userId]/index.vue")
  },
  {
    name: "settings-users-userId-permissions___en",
    path: "users/:userId()/permissions",
    meta: permissions0zU1Dj9lCPMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/[userId]/permissions.vue")
  },
  {
    name: "settings-users___en",
    path: "users",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/index.vue")
  },
  {
    name: "settings-users-invite-user___en",
    path: "users/invite-user",
    meta: invite_45userqf0BovFMjmMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/invite-user.vue")
  }
]
  },
  {
    name: "settings___pt-BR",
    path: "/pt-BR/settings",
    component: () => import("/opt/build/repo/layers/settings/pages/settings.vue"),
    children: [
  {
    name: confidence_45scoreFdkauB6XjlMeta?.name,
    path: "confidence-score",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score.vue"),
    children: [
  {
    name: "settings-confidence-score-groupId___pt-BR",
    path: ":groupId()",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score/[groupId].vue")
  },
  {
    name: "settings-confidence-score___pt-BR",
    path: "",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score/index.vue")
  }
]
  },
  {
    name: "settings-divisions-create___pt-BR",
    path: "divisions/create",
    meta: createlGCGcpviS0Meta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/divisions/create.vue")
  },
  {
    name: "settings-divisions___pt-BR",
    path: "divisions",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/divisions/index.vue")
  },
  {
    name: "settings-profile___pt-BR",
    path: "profile",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/profile/index.vue")
  },
  {
    name: "settings-upload-reference___pt-BR",
    path: "upload-reference",
    meta: index9pVNJwoO9tMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/upload-reference/index.vue")
  },
  {
    name: "settings-users-userId___pt-BR",
    path: "users/:userId()",
    meta: indexmQbwge5nNcMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/[userId]/index.vue")
  },
  {
    name: "settings-users-userId-permissions___pt-BR",
    path: "users/:userId()/permissions",
    meta: permissions0zU1Dj9lCPMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/[userId]/permissions.vue")
  },
  {
    name: "settings-users___pt-BR",
    path: "users",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/index.vue")
  },
  {
    name: "settings-users-invite-user___pt-BR",
    path: "users/invite-user",
    meta: invite_45userqf0BovFMjmMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/invite-user.vue")
  }
]
  },
  {
    name: "unsupported-browser___en",
    path: "/unsupported-browser",
    meta: unsupported_45browserHJmWimS945Meta || {},
    component: () => import("/opt/build/repo/pages/unsupported-browser.vue")
  },
  {
    name: "unsupported-browser___pt-BR",
    path: "/pt-BR/unsupported-browser",
    meta: unsupported_45browserHJmWimS945Meta || {},
    component: () => import("/opt/build/repo/pages/unsupported-browser.vue")
  }
]