<script setup lang="ts">
import type { IOptionExerciseDetailsValue } from '@register/components/Review/Form/OptionExerciseDetails/types'

const props = defineProps<{
  exercisable: boolean
  item: IOptionExerciseDetailsValue
  previousItem?: IOptionExerciseDetailsValue
  nextItem?: IOptionExerciseDetailsValue
  isLast: boolean
  isFirst: boolean
}>()
defineEmits<{
  (e: 'exercise', value: IOptionExerciseDetailsValue): void
}>()

const dayjs = useDayjs()

const isNextOptionExercised = computed(
  () => props.exercisable && !props.isLast && props.nextItem?.isExercised,
)

const isPreviousOptionNotExercised = computed(
  () => props.exercisable && !props.isFirst && !props.previousItem?.isExercised,
)

const isStartDateBeforeToday = computed(
  () =>
    !props.item.isExercised && dayjs().isBefore(props.item.exerciseStartDate),
)

const tooltipClass = computed(() =>
  isNextOptionExercised.value ||
  isPreviousOptionNotExercised.value ||
  isStartDateBeforeToday.value
    ? 'bg-warning text-gray-950'
    : 'text-white bg-danger',
)

const tooltipContent = computed(() => {
  if (isNextOptionExercised.value) {
    return 'You cannot cancel the exercise of this option because the next option is exercised'
  } else if (isPreviousOptionNotExercised.value) {
    return 'You cannot exercise this options because the previous option is not exercised'
  } else if (isStartDateBeforeToday.value) {
    return 'You cannot exercise this option because the start date is in the future'
  } else {
    return props.item.isExercised
      ? 'Cancel this option'
      : 'Exercise this option'
  }
})

const isButtonDisabled = computed(
  () =>
    !props.exercisable ||
    isNextOptionExercised.value ||
    isPreviousOptionNotExercised.value ||
    isStartDateBeforeToday.value,
)
</script>

<template>
  <Tooltip
    class="max-w-68 ml-2 text-center text-xs"
    :class="tooltipClass"
    :content="tooltipContent"
  >
    <button
      class="relative ml-4 flex h-6 w-6 items-center justify-center self-center rounded-full"
      :class="item.isExercised ? 'bg-primary' : 'bg-gray-750'"
      :disabled="isButtonDisabled"
      @click.prevent="$emit('exercise', item)"
    >
      <NuxtIcon v-if="item.isExercised" name="check" class="text-black" />
      <span v-else>-</span>
    </button>
  </Tooltip>
</template>
