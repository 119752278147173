import { IMe } from './useAPI/types'

export const useNewRelic = () => {
  const newRelic = useNuxtApp().$newRelic
  const runtimeConfig = useRuntimeConfig()

  function actionText(text: string) {
    newRelic.interaction().actionText(text)
  }

  function log(text: string) {
    newRelic.setCustomAttribute('Custom Log', text)

    if (runtimeConfig.public.ENV === 'local') {
      console.log(text)
    }
  }

  function login(user: AuthUser) {
    newRelic.setUserId(user?.id ?? 'anonymous')

    newRelic.setCustomAttribute('user', user?.name ?? 'anonymous')
    newRelic.setCustomAttribute('email', user?.email ?? 'anonymous@email.com')

    newRelic.setCustomAttribute(
      'organization.name',
      user?.organization.name ?? 'anonymous',
    )

    newRelic.setCustomAttribute(
      'organization.id',
      user?.organization.id ?? 'anonymous',
    )

    if (user?.permissions) {
      const permissions = user?.permissions.map((permission) => {
        return permission.roleId
      })
      newRelic.setCustomAttribute('permissions', permissions.join(', '))
    }

    newRelic.setCustomAttribute('products', user?.products.join(', ') ?? 'N/A')
  }

  function logout() {
    newRelic.setCustomAttribute('user', 'anonymous')
    newRelic.setCustomAttribute('email', 'anonymous@email.com')
    newRelic.setUserId(null)
  }

  function setCustomAttribute(key: string, value: string) {
    newRelic.setCustomAttribute(key, value)
  }

  // set this on all request errors and try catch errors
  function setError(error: Error) {
    newRelic.noticeError(error)
  }

  function addPageAction(name: string, attributes?: object | undefined) {
    newRelic.addPageAction(name, attributes)
  }

  return {
    actionText,
    log,
    login,
    logout,
    setCustomAttribute,
    setError,
    addPageAction,
  }
}
