<script setup lang="ts">
import ActionButton from '../ActionButton.vue'
import type { LeaseDocumentInputContext } from '../../types'
import { isNullish } from '@morev/utils'
import { toast } from 'vue3-toastify'

const props = defineProps<{ context: LeaseDocumentInputContext }>()

const selectedText = computed(() => props.context.selectedText)
const isApproved = computed(() => props.context.isApproved)
const disabled = computed(
  () => props.context.disabled || isApproved.value || !selectedText.value,
)
const tooltip = computed(() => {
  if (isApproved.value) return 'Unable to modify approved field'
  if (!selectedText.value) return 'Select the text to highlight'
  return `Highlight selected text`
})
const buttonClass = computed(() =>
  disabled.value
    ? 'cursor-help opacity-40 hover:text-black dark:hover:text-white'
    : undefined,
)
const onClick = async () => {
  if (disabled.value) return
  // Set value
  const { value: _value, bounds } = selectedText.value!
  const value = props.context.fieldConfig.fromDocument(_value)
  if (isNullish(value)) {
    toast.error(
      props.context.fieldConfig.fromDocumentError ??
        'Unable to backlinking the selected text',
    )
    useAnalytics({
      name: 'Lease Field Toolbar Actions',
      data: {
        source: 'Backlinking only From PDF Error',
        'Is Edited': props.context.isEdited,
        'Field ID': props.context.id,
        'Field Value': props.context._value.value || props.context.value,
        context: props.context,
      },
    })
    return
  }

  await props.context.node.input({
    value: props.context.node.value?.value,
    bounds,
  })
  // Emit
  props.context.handlers.backlinkingOnly(props.context.node)

  useAnalytics({
    name: 'Lease Field Toolbar Actions',
    data: {
      source: 'Backlinking Only From PDF',
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}
</script>

<template>
  <ActionButton
    :tooltip="tooltip"
    icon="backlinkingOnly"
    :disabled="disabled"
    :button-class="buttonClass"
    @click="onClick"
  />
</template>
