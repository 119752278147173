import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { FieldGroupService } from '~/data-services/manager'
import {
  createGroupURIFactory,
  generateIdPathMap,
  ILeaseManagerSidebarSection,
  type INodeGroup,
} from '@manager'

const fetchFieldGroupByLeaseId = async (
  divisionId: string,
  leaseId: string,
) => {
  const createGroupURI = createGroupURIFactory(divisionId, leaseId)

  const response = await FieldGroupService.getFieldGroupByleaseid(
    // TODO: Remove default `leaseId`
    // Number(leaseId),
    0,
  )
  return (response.groups ?? []).map((group) => {
    return {
      id: String(group.id!),
      title: group.name!,
      referenceName: group.referenceName!,
      items: (group.groups ?? []).map((section) => {
        const id = String(section.id!)
        const referenceName = section.referenceName!
        return {
          id,
          title: section.name!,
          icon: 'review/' + section.referenceName,
          referenceName,
          to: createGroupURI({ id, referenceName }),
        }
      }),
    }
  }) as ILeaseManagerSidebarSection[]
}

export const useApiManagerGroupAndSections = (
  divisionId: string,
  leaseId: string,
) => {
  return useQuery({
    queryKey: ['manager', 'FieldGroup', 'byleaseid', leaseId],
    queryFn: () => fetchFieldGroupByLeaseId(divisionId, leaseId),
  })
}

export const fetchManagerGroupAndSections = async (
  divisionId: string,
  leaseId: string,
) => {
  const queryClient = useQueryClient()
  return queryClient.fetchQuery({
    queryKey: ['manager', 'FieldGroup', 'byleaseid', leaseId],
    queryFn: () => fetchFieldGroupByLeaseId(divisionId, leaseId),
  })
}

type QueryKey = [
  string,
  string,
  number,
  { includeFields: boolean; recursive: boolean },
]

export const useApiManagerFieldGroupV2 = (fieldGroupId: number) => {
  const params = {
    includeFields: true,
    recursive: true,
  }

  return useQuery({
    queryKey: ['manager', 'FieldGroupV2', fieldGroupId, params] as QueryKey,
    queryFn: fetchFieldGroupV2,
  })
}

export const useManagerFieldGroup = async (fieldGroupId: number) => {
  const query = useApiManagerFieldGroupV2(fieldGroupId)
  const { idPathByPage } = storeToRefs(useManagerStore())

  await query
    .suspense()
    .then(({ data }) => {
      if (!data) throw new Error('No data found for field group')
      idPathByPage.value.set(fieldGroupId, generateIdPathMap(data))
    })
    .catch((e) => {
      throw createError({
        message: 'Failed to load field group',
        statusCode: e.response?.status,
        cause: e,
        fatal: true,
      })
    })

  return query
}

export const usePrefetchManagerFieldGroupV2 = () => {
  const queryClient = useQueryClient()
  const params = {
    includeFields: true,
    recursive: true,
  }

  return async (fieldGroupId: number) => {
    await queryClient.prefetchQuery({
      queryKey: ['manager', 'FieldGroupV2', fieldGroupId, params] as QueryKey,
      queryFn: fetchFieldGroupV2,
    })
  }
}

// Access the key, status and page variables in your query function!
function fetchFieldGroupV2({ queryKey }: { queryKey: QueryKey }) {
  const [_layer, _key, fieldGroupId, params] = queryKey
  return FieldGroupService.getFieldGroupV2(
    fieldGroupId,
    params.includeFields,
    params.recursive,
  ) as unknown as INodeGroup
}
