/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeaseFieldDataType {
    STRING = 'string',
    INTEGER = 'integer',
    DECIMAL = 'decimal',
    PERCENTAGE = 'percentage',
    DATE = 'date',
    BOOLEAN = 'boolean',
    GUID = 'guid',
    CURRENCY = 'currency',
    OBJECT = 'object',
    ARRAY = 'array',
    ENUM = 'enum',
}
