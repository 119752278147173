import { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  type FormKitInputs,
} from '@formkit/inputs'
import { numberIncrementInput } from './sections/numberIncrementInput'

declare module '@formkit/inputs' {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    'number-increment': {
      type: 'number-increment'
      value?: number
      min?: number
      max?: number
    }
  }
}

/**
 * Input definition for a select.
 * @public
 */
export const numberIncrement: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      label('$label'),
      inner(
        icon('prefix'),
        prefix(),
        numberIncrementInput(),
        suffix(),
        icon('suffix'),
      ),
    ),
    help('$help'),
    messages(message('$message.value')),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['min', 'max'],
}
