import { LeaseVariationKindWithField } from '@register'
import { LeaseFieldDataType, LeaseFieldType } from '@register'
import type { VariationFieldConfigFactory } from './types'

//TODO: fix type
export const definitionFactory: VariationFieldConfigFactory = ({
  kind,
  leaseFieldId,
  clausesAndDefinitions,
}) => {
  return {
    key: 'definition',
    leaseField: {
      label: 'Definition',
      options: getOptions(),
    },
    value: {
      show: kind !== LeaseVariationKindWithField.REMOVE_DEFINITION,
      type: 'textarea',
      field: getField(),
    },
  }

  function getOptions() {
    let filtered = clausesAndDefinitions.filter((clause) =>
      clause.id.endsWith('Definition'),
    )

    if (kind === LeaseVariationKindWithField.REMOVE_CLAUSE) {
      filtered = filtered.filter((clause) => !!clause.value)
    }

    return filtered.map(({ id, name }) => ({
      label: name.replace('Definition', ''),
      value: id,
    }))
  }

  function getField() {
    if (!leaseFieldId) return

    // FIELD
    const field = clausesAndDefinitions.find(
      (field) => field.id === leaseFieldId,
    )
    if (field) return field

    // DEFAULT FIELD
    return {
      id: leaseFieldId!,
      name: leaseFieldId!,
      fieldType: LeaseFieldType.DEFINITION,
      dataType: LeaseFieldDataType.STRING,
      description: '',
    }
  }
}
