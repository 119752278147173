import * as Sentry from '@sentry/browser'
import { defineNuxtPlugin } from '#app'
import * as pkg from '~/package.json'

export default defineNuxtPlugin((nuxtApp) => {
  if (nuxtApp.$config.public.ENV === 'local') {
    return
  }

  const isProd =
    nuxtApp.$config.public.ENV === 'production'
      ? 'accurait-prod'
      : 'accurait-dev'

  const release = `${isProd}@${pkg.version}`
  const environment = nuxtApp.$config.public.ENV
  const dsn = nuxtApp.$config.public.sentry.dsn

  Sentry.init({
    dsn,
    release,
    environment,
    integrations: [
      Sentry.browserApiErrorsIntegration(),
      Sentry.browserTracingIntegration({
        // tracingOrigins: ['*'],
      }),
      Sentry.replayIntegration(),
      // new Sentry.BrowserProfilingIntegration(),
      Sentry.browserProfilingIntegration()
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
      '*',
      nuxtApp.$config.public.baseURL,
      nuxtApp.$config.public.apiURL,
      nuxtApp.$config.public.managerURL,
    ],

    sampleRate: 1,
    tracesSampleRate: 1,
  })
})
