<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../../types'
import { useLeaseDates } from '@register'
import { useFormModal, useDelete } from './composables'
import Lock from '@ui/formkit/inputs/leaseDocumentInput/actions/lock/Lock.vue'
import type { RentReviewTableValue } from '@register/components/Review/Form/RentReviewTable/types'

const props = defineProps<{ context: LeaseDocumentInputContext }>()
const { hasLeaseDates, expiryDate, commencementDate } = useLeaseDates()
const openForm = useFormModal(toRef(props, 'context'), {
  commencementDate,
  expiryDate,
})
const onDelete = useDelete(toRef(props, 'context'))

const { dateFormat: divisionDateFormat } = useDivisions()
const { dateFormat: leaseDateFormat } = useCurrentLeaseDocument()

const data = computed<RentReviewTableValue[]>(() => {
  // The original value can be a string, so we need to convert it to an empty array.
  if (!props.context.value.value || !Array.isArray(props.context.value.value)) {
    return []
  }

  return props.context.value.value
})
</script>

<template>
  <div v-auto-animate>
    <!-- Table -->
    <ReviewFormRentReviewTable
      :data="data"
      :label="context.label"
      :disabled="context.disabled"
      :is-required="context.field.isRequired"
      :is-approved="context.isApproved"
      :expiry-date="expiryDate"
      :commencement-date="commencementDate"
      :division-date-format="divisionDateFormat"
      :lease-date-format="leaseDateFormat"
      review-date-format="both"
      @edit="openForm"
      @delete="onDelete"
      @add="openForm"
    >
      <template #footer>
        <div v-if="context.canApprove" class="flex justify-end p-2">
          <Lock
            :context="context"
            :force-tooltip="
              context.isApproved
                ? `Edit ${context.field.name}`
                : `Approve ${context.field.name}`
            "
            title="Approve table"
          />
        </div>
      </template>
    </ReviewFormRentReviewTable>

    <div
      v-if="!hasLeaseDates"
      class="mt-4 rounded-lg border border-yellow-500 bg-yellow-500/10 p-4 text-yellow-500"
    >
      Commencement Date and Expiry Date must be approved.
    </div>
  </div>
</template>
