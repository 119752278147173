import { createMessage, type FormKitNode } from '@formkit/core'
import { has, clone } from '@formkit/utils'

async function handleSubmit(node: FormKitNode, submitEvent: Event) {
  submitEvent.preventDefault()

  await node.settled

  // Set the submitted state on all children
  node.walk((n) => {
    n.store.set(
      createMessage({
        key: 'submitted',
        value: true,
        visible: false,
      }),
    )
  })

  // Await files to upload
  // TODO: If upload failed, should stop form from being sent.
  // node.disturbLoading()
  // await filesPromise(node)
  // node.calmLoading()

  if (typeof node.props.onSubmitRaw === 'function') {
    node.props.onSubmitRaw(submitEvent, node)
  }

  if (node.ledger.value('blocking')) {
    if (typeof node.props.onSubmitInvalid === 'function') {
      node.props.onSubmitInvalid(node)
    }
    // There is still a blocking message in the store.
    if (node.props.incompleteMessage !== false) {
      node.store.set(
        createMessage({
          blocking: false,
          key: `incomplete`,
          meta: {
            localize: node.props.incompleteMessage === undefined,
            i18nArgs: [{ node }],
            showAsMessage: true,
          },
          type: 'ui',
          value: node.props.incompleteMessage || 'Form incomplete.',
        }),
      )
    }
  } else if (typeof node.props.onSubmit === 'function') {
    // call onSubmit
    const retVal = node.props.onSubmit(
      node.hook.submit.dispatch(clone(node.value as Record<string, any>)),
      node,
    )

    if (retVal instanceof Promise) {
      node.disturbLoading()

      await retVal
        .then((response) => {
          node.emit('submit:success', response)
          return response
        })
        .catch((e) => {
          node.emit('submit:error', e)
        })

      node.calmLoading()
    }
  } else if (submitEvent.target instanceof HTMLFormElement) {
    submitEvent.target.submit()
  }
}

/**
 * Converts the options prop to usable values.
 * @param node - A formkit node.
 * @public
 */
export default function form(node: FormKitNode): void {
  node.props.isForm = true
  node.on('created', () => {
    if (node.context?.handlers) {
      node.context.handlers.submit = handleSubmit.bind(null, node)
    }
    if (!has(node.props, 'actions')) {
      node.props.actions = true
    }
  })
  node.on('settled:blocking', () => node.store.remove('incomplete'))
}
