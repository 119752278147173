import {
  FieldInputTypes,
  FieldUsage,
  FieldValueType,
  type INode,
  type INodeField,
} from '@manager'
import type { INodeDynamicComponentConfig } from './types'

export const FieldConfig: INodeDynamicComponentConfig = {
  glob: () => import.meta.glob('@manager/components/Field/**/*.vue'),
  directory: '/layers/manager/components/Field',
  path: (node) => ['Custom', node.name],
  defaultComponent: (node: INode) => {
    if ((node as INodeField).usage === FieldUsage.CALCULATED) {
      return 'Calculated'
    }

    if ((node as INodeField).valueType === FieldValueType.CURRENCY) {
      return 'Currency'
    }

    if ((node as INodeField).valueType === FieldValueType.MONTH_AND_DAY) {
      return 'MonthDayPicker'
    }

    switch ((node as INodeField).type) {
      case FieldInputTypes.BUTTON:
        return 'Button'
      case FieldInputTypes.DROPDOWN:
        return 'Dropdown'
      case FieldInputTypes.DATE:
        return 'Datepicker'
      case FieldInputTypes.INCREMENT:
        return 'NumberIncrement'
      default:
        return 'Field'
    }
  },
}
