import {
  tv,
  type TVCompoundSlots,
  TVCompoundVariants,
  TVDefaultVariants,
  TVProps,
  TVVariants,
} from 'tailwind-variants'
import { TVConfig } from 'tailwind-variants/dist/config'
import { baseTanstackTable } from '../style'

export const useTanstackTableVariants = <
  V extends TVVariants<
    S,
    B,
    typeof baseTanstackTable.variants,
    typeof baseTanstackTable.slots
  >,
  CV extends TVCompoundVariants<
    V,
    S,
    B,
    typeof baseTanstackTable.variants,
    typeof baseTanstackTable.slots
  >,
  DV extends TVDefaultVariants<
    V,
    S,
    typeof baseTanstackTable.variants,
    typeof baseTanstackTable.slots
  >,
  C extends TVConfig<V, typeof baseTanstackTable.variants>,
  B extends typeof baseTanstackTable.base = undefined,
  S extends typeof baseTanstackTable.slots = typeof baseTanstackTable.slots,
>(
  options: {
    /**
     * Base allows you to set a base class for a component.
     */
    base?: B
    /**
     * Slots allow you to separate a component into multiple parts.
     * @see https://www.tailwind-variants.org/docs/slots
     */
    slots?: Partial<S>
    /**
     * Variants allow you to create multiple versions of the same component.
     * @see https://www.tailwind-variants.org/docs/variants#adding-variants
     */
    variants?: V
    /**
     * Compound variants allow you to apply classes to multiple variants at once.
     * @see https://www.tailwind-variants.org/docs/variants#compound-variants
     */
    compoundVariants?: CV
    /**
     * Compound slots allow you to apply classes to multiple slots at once.
     */
    compoundSlots?: TVCompoundSlots<V, S, B>
    /**
     * Default variants allow you to set default variants for a component.
     * @see https://www.tailwind-variants.org/docs/variants#default-variants
     */
    defaultVariants?: DV
  },
  props?: TVProps<
    V,
    S,
    C,
    typeof baseTanstackTable.variants,
    typeof baseTanstackTable.slots
  >,
) => {
  // @ts-expect-error
  const _tv = tv({
    extend: baseTanstackTable,
    ...options,
  })

  return { tv: _tv, props }
}

export type TanstackTableVariant = ReturnType<typeof useTanstackTableVariants>
