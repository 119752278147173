/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldGroupRequest } from '../models/FieldGroupRequest';
import type { FieldGroupRequestFullTree } from '../models/FieldGroupRequestFullTree';
import type { IFieldGroup } from '../models/IFieldGroup';
import type { NodeFieldResponse } from '../models/NodeFieldResponse';
import type { NodeGroupResponse } from '../models/NodeGroupResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FieldGroupService {
    /**
     * @param fieldGroupId
     * @param accountId
     * @param fieldGroupParentId
     * @param requestBody
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static putFieldGroup(
        fieldGroupId: number,
        accountId?: string,
        fieldGroupParentId?: number,
        requestBody?: FieldGroupRequest,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/FieldGroup/{fieldGroupId}',
            path: {
                'fieldGroupId': fieldGroupId,
            },
            query: {
                'accountId': accountId,
                'fieldGroupParentId': fieldGroupParentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fieldGroupId
     * @param includeFields
     * @param recursive
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static getFieldGroup(
        fieldGroupId: number,
        includeFields: boolean = false,
        recursive: boolean = true,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/FieldGroup/{fieldGroupId}',
            path: {
                'fieldGroupId': fieldGroupId,
            },
            query: {
                'includeFields': includeFields,
                'recursive': recursive,
            },
        });
    }
    /**
     * @param accountId
     * @param fieldGroupParentId
     * @param requestBody
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static postFieldGroup(
        accountId?: string,
        fieldGroupParentId?: number,
        requestBody?: FieldGroupRequest,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/FieldGroup',
            query: {
                'accountId': accountId,
                'fieldGroupParentId': fieldGroupParentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fieldGroupId
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteFieldGroup(
        fieldGroupId?: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/FieldGroup',
            query: {
                'fieldGroupId': fieldGroupId,
            },
        });
    }
    /**
     * @param accountId
     * @param fieldGroupParentId
     * @param requestBody
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static postFieldGroupFulltree(
        accountId?: string,
        fieldGroupParentId?: number,
        requestBody?: FieldGroupRequestFullTree,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/FieldGroup/fulltree',
            query: {
                'accountId': accountId,
                'fieldGroupParentId': fieldGroupParentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param accountId
     * @param fieldGroupParentId
     * @param fieldGroupTemplateId
     * @param requestBody
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static postFieldGroupFromtemplate(
        accountId?: string,
        fieldGroupParentId?: number,
        fieldGroupTemplateId?: number,
        requestBody?: FieldGroupRequestFullTree,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/FieldGroup/fromtemplate',
            query: {
                'accountId': accountId,
                'fieldGroupParentId': fieldGroupParentId,
                'fieldGroupTemplateId': fieldGroupTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param name
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static getFieldGroupByname(
        name: string,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/FieldGroup/byname/{name}',
            path: {
                'name': name,
            },
        });
    }
    /**
     * @param leaseId
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static getFieldGroupByleaseid(
        leaseId: number,
    ): CancelablePromise<IFieldGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/FieldGroup/byleaseid/{leaseId}',
            path: {
                'leaseId': leaseId,
            },
        });
    }
    /**
     * @param accountId
     * @returns IFieldGroup Success
     * @throws ApiError
     */
    public static getFieldGroupList(
        accountId: string,
    ): CancelablePromise<Array<IFieldGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/FieldGroup/list/{accountId}',
            path: {
                'accountId': accountId,
            },
        });
    }
    /**
     * @param fieldGroupId
     * @param includeFields
     * @param recursive
     * @returns NodeGroupResponse Success
     * @throws ApiError
     */
    public static getFieldGroupV2(
        fieldGroupId: number,
        includeFields: boolean = false,
        recursive: boolean = true,
    ): CancelablePromise<NodeGroupResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/FieldGroup/V2/{fieldGroupId}',
            path: {
                'fieldGroupId': fieldGroupId,
            },
            query: {
                'includeFields': includeFields,
                'recursive': recursive,
            },
        });
    }
    /**
     * @param fieldGroupId
     * @param includeFields
     * @param recursive
     * @returns NodeFieldResponse Success
     * @throws ApiError
     */
    public static getFieldGroupDoNotUse(
        fieldGroupId: number,
        includeFields: boolean = false,
        recursive: boolean = true,
    ): CancelablePromise<NodeFieldResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/FieldGroup/DoNotUse/{fieldGroupId}',
            path: {
                'fieldGroupId': fieldGroupId,
            },
            query: {
                'includeFields': includeFields,
                'recursive': recursive,
            },
        });
    }
}
