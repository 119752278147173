import type {
  DehydratedState,
  VueQueryPluginOptions,
} from '@tanstack/vue-query'

import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate,
} from '@tanstack/vue-query'

export default defineNuxtPlugin((nuxt) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 24 * 7, // 24 hours
        staleTime: 1000 * 30, // 30 seconds
        refetchOnWindowFocus: false,
        retryDelay: (attemptIndex: number) =>
          Math.min(1000 * 2 ** attemptIndex, 30000),
      },
    },
  })

  const pluginOptions: VueQueryPluginOptions = {
    queryClient,
  }

  nuxt.vueApp.use(VueQueryPlugin, pluginOptions)
  const vueQueryClient = useState<DehydratedState | null>('vue-query')

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryClient.value = dehydrate(queryClient)
    })
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryClient.value)
    })
  }
})
